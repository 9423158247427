import { EnumE6CardType } from "@wallet-manager/pfh-node-def-types/dist/src/E6Enum";

// this one will bound to EnumE6CardType
// for filter usage
export const getCardTypeDisplayValue = (
  cardType: string,
  translate: (val: string) => string
) => {
  const value = EnumE6CardType[cardType as keyof typeof EnumE6CardType];

  if (!value) {
    return "";
  }

  return translate(`EnumE6CardType.${value}`);
};

// this one directly translate the card type
export const directTranslateCardType = (
  cardType: string,
  translate: (val: string) => string
) => {
  if (!cardType) return "";

  return translate(`EnumE6CardType.${cardType}`);
};
