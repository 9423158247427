import { ChangeEvent, useState } from 'react';

import { Container } from '@mui/system';
import {
    EnumE6TransactionSubType,
    EnumE6TransactionType
  } from '@wallet-manager/pfh-node-def-types/dist/src/DbModel/Statement';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import { Filter } from '../../../features/common/filterTable/types';
import { useTranslation } from '../../../hooks';
import {
    Ifield,
    initFields,
    initZusParams,
    IsearchParam,
    translateKeyObj as TK,
    translatePrefix,
    useZusParams
  } from './config';
import { useDatePicker } from '../../../components/DatePicker';
import { SelectChangeEvent } from '../../../components/MuiGenerals';
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import ProgramNameMultipleSelection from '../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import { MpTextField, MultipleSelection, SingleSelection } from '../../../components';
import { useGetRule } from '../../../hooks/query/useRule';

const availableEnumE6TransactionType = Object.fromEntries(
  Object.entries(EnumE6TransactionType).filter(
      ([key, value]) =>
          String(value) === String(EnumE6TransactionType.Withdraw) ||
          String(value) === String(EnumE6TransactionType.Purchase)
  )
);



function ViewFilter() {
    const { t, tc } = useTranslation(translatePrefix);
    const [fields, setFields] = useState<Ifield>(initFields);

    const ruleObj = useGetRule();

    const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();
    
    const DateObj = {
        CreationTime: useDatePicker(),
        TransactionTime: useDatePicker(),
        SettlementTime: useDatePicker(),
    };

    const zusParams = useZusParams();

    const getParams: () => IsearchParam = () => {
        const { start: creationTimeFrom, end: creationTimeTo } = DateObj.CreationTime;
        const { start: transactionTimeFrom, end: transactionTimeTo } = DateObj.TransactionTime;
        const { start: settlementDateFrom, end: settlementDateTo } = DateObj.SettlementTime;
        const {
            programName: programName,
            transactionType,
            transactionSubType,
            ...rest
        } = fields;

        return {
            ...initZusParams,
            ...rest,
            creationTimeFrom : transactionTimeFrom,
            creationTimeTo : transactionTimeTo,
            createdDateFrom: creationTimeFrom,
            createdDateTo: creationTimeTo,
            settlementDateFrom,
            settlementDateTo,
            programName,
            transactionTypes: transactionType ? [transactionType]: undefined,
            transactionSubTypes: transactionSubType ? [transactionSubType]: undefined,
        };
    };

    const apiParams = getParams();

    const onSearch = () => {
        zusParams.setBody({ ...apiParams, page: 0 });
        zusParams.refetch();
    };

    const onReset = () => {
        setFields(initFields);
        DateObj.CreationTime.clearDate();
        DateObj.TransactionTime.clearDate();
        DateObj.SettlementTime.clearDate();
    };

    const onChange =
    (field: keyof typeof fields) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

    const isSubTypeFilterDisabled = !fields.transactionType;

    const filters: Filter[] = [
        {
            labelKey: TK.creationTime,
            filter: <DateObj.CreationTime.Picker type="dateTime" />,
        },
        {
            labelKey: TK.transactionTime,
            filter: <DateObj.TransactionTime.Picker type="dateTime" />,
        },
        {
            labelKey: TK.settlementTime,
            filter: <DateObj.SettlementTime.Picker type="dateTime" />,
        },
        {
            labelKey: TK.ruleName,
            filter: <SingleSelection
                label={tc("phSelection", { fieldName: t(TK.ruleName) })}
                value={fields.ruleName}
                onChange={onChange("ruleName")}
                clearSelect={() =>
                    setFields((fields) => ({ ...fields, merchantId: "" }))
                }
                nameFn={(name) => name}
                isNoSorting
                enumData={ruleObj} 
            />
        },
        {
            labelKey: TK.programName,
            filter: (
              <ProgramNameMultipleSelection
                value={fields.programName}
                onChange={(value: string[]) =>
                  setFields((fields) => ({ ...fields, programName: value }))
                }
                onClear={() =>
                  setFields((fields) => ({ ...fields, programName: [] }))
                }
              />
            ),
          },
          {
            labelKey: TK.customerNumber,
            filter: (
              <MpTextField
                label={tc("phInputField", { fieldName: t(TK.customerNumber) })}
                value={fields.customerNumber}
                onChange={onChange("customerNumber")}
              />
            ),
          },
          {
            labelKey: TK.transactionId,
            filter: (
              <MpTextField
                label={tc("phInputField", { fieldName: t(TK.transactionId) })}
                value={fields.transactionId}
                onChange={onChange("transactionId")}
              />
            ),
          },
          {
            labelKey: TK.panLast4,
            filter: (
              <MpTextField
                label={tc("phInputField", { fieldName: t(TK.panLast4) })}
                value={fields.panLast4}
                onChange={onChange("panLast4")}
              />
            ),
          },
          {
            labelKey: TK.transactionType,
            filter: (
              <SingleSelection
                label={tc("phSelection", { fieldName: t(TK.transactionType) })}
                value={fields.transactionType}
                onChange={(e) => {
                  setFields((fields) => ({...fields, transactionType: e.target.value}));
                }}
                clearSelect={() => {
                  setFields((fields) => ({ ...fields, transactionType: '' }));
                  }
                }
                enumData={availableEnumE6TransactionType}
              />
            ),
          },
          {
            labelKey: TK.subType,
            filter: (
              <SingleSelection
                label={tc("phSelection", { fieldName: t(TK.subType) })}
                value={fields.transactionSubType}
                onChange={ (e) => {
                  setFields((fields) => ({...fields, transactionSubType: e.target.value}));
                  }
                }
                clearSelect={() => {
                    setFields((fields) => ({ ...fields, transactionSubType: '' }));
                  }
                }
                enumData={
                  isSubTypeFilterDisabled
                    ? { [t("ph_transaction_sub_type")]: "" }
                    : EnumE6TransactionSubType[
                        Number([fields.transactionType].toString()) as EnumE6TransactionType
                      ]
                }
                nameFn={isSubTypeFilterDisabled ? (name) => name : undefined}
              />
            ),
          },
          {
            labelKey: TK.paymentMethod,
            filter: (
              <MpTextField
                label={tc("phInputField", { fieldName: t(TK.paymentMethod) })}
                value={fields.paymentMethod}
                onChange={onChange("paymentMethod")}
              />
            ),
          },
          {
            labelKey: TK.authorizationCode,
            filter: (
              <MpTextField
                label={tc("phInputField", { fieldName: t(TK.authorizationCode) })}
                value={fields.authCode}
                onChange={onChange("authCode")}
              />
            ),
          },

    ];

    return (
        <Container disableGutters maxWidth={false}>
            <FilterSectionActionRow>
                <ToggleFilterButton
                    isCollapse={isCollapse}
                    onClick={toggleFilterCollapse}
                />
            </FilterSectionActionRow>
    
            <CollapsibleFilters
                isCollapse={isCollapse}
                filters={filters}
                onSearch={onSearch}
                onReset={onReset}
            />
      </Container>
    );
}

export default ViewFilter;