import "../../../src/App.module.scss";

import { useState } from "react";
import { MenuItemLink, useTranslate } from "react-admin";

import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import { usePermission } from "../../hooks";
import { routeList } from "../../hooks/useRoutePermission";
import { COLOR } from "../../utils/config";
import { versionNumber } from "../../utils/constant";
import { useZusListStore } from "../../zustand/store";
import CreditCardLogo from "../CreditCardLogo";
import MenuStackWithSubTitle from "./MenuStackWithSubTitle";

const sx = {
  logo: {
    padding: "30px",
  },
  menuPadding: {
    paddingLeft: "10px",
  },
  expanded: {
    color: COLOR.text.primary.hex,
    boxShadow: "none",
  },
  notExpanded: {
    color: COLOR.text.secondary.hex,
    boxShadow: "none",
  },
  muiAccordion: {
    "&.MuiAccordion-root:before": { content: "none" },
    "& .MuiAccordionSummary-expandIconWrapper": {
      transform: "rotate(270deg)",
      color: COLOR.text.secondary.hex,
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(360deg)",
      ">svg": { color: `${COLOR.text.primary.hex} !important` },
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      maxHeight: "30px",
      margin: "5px 0px",
    },
  },
  accordionSummary: {
    "&.Mui-expanded": {
      minHeight: 0,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0px",
    },
  },
  accordionDetails: {
    padding: "5px",
    a: {
      color: `rgba(${COLOR.text.secondary.rgb}, 0.7)`,
      "&.RaMenuItemLink-active": {
        color: COLOR.text.primary.hex,
      },
    },
    "div:hover": {
      a: {
        backgroundColor: `${COLOR.background.secondary.hex} !important`,
        color: COLOR.text.secondary.hex,
        "&.RaMenuItemLink-active": {
          color: COLOR.text.primary.hex,
        },
      },
    },
  },
};

export default function Menu(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`menu.${key}`);
  const [selected, setSelected] = useState("");
  const { hasPermission } = usePermission();
  // const L = useRoutePermission().list;
  const L = routeList;
  // const onAssetManagementOpen = translate(`menu.assetManagement`) === selected;
  // const onLedgerOpen = translate(`menu.ledger`) === selected;

  //###add-new-page

  const dataAnalysisMenu = [
    {
      title: t("consumption"),
      list: [
        L.byProgramConsumption,
        L.byCountryConsumption,
        L.byPfhNoConsumption,
        L.byMccConsumption,
        L.byPaymentMethodConsumption,
      ],
    },
    {
      title: t("withdraw"),
      list: [L.byProgramWithdraw, L.byPfhNoWithdraw, L.byCountryWithdraw],
    },
  ];

  return (
    <div style={sx.menuPadding}>
      <div style={sx.logo}>
        <CreditCardLogo />
      </div>
      <div style={{ height: "calc(100vh - 9.5rem)" }}>
        <MenuStack
          selected={selected}
          setSelected={setSelected}
          title={t("setting")}
          list={[L.cardProfileSetting, L.assignCardProfile]}
        />
        <MenuStack
          selected={selected}
          setSelected={setSelected}
          title={t("creditManagement")}
          list={[
            L.customerAccount,
            L.merchantWallet,
            L.customerWallet,
            L.feeWallet,
            L.merchantDepositRequest,
            L.creditAdjustmentRequest,
            L.balanceAdjustmentRequest,
            L.repaymentSchedule,
            L.walletTransaction,
            L.cardTransaction,
            L.cardOrderRequest,
            L.preCreateCardRequest,
            L.cardList,
          ]}
        />
        <MenuStack
          selected={selected}
          setSelected={setSelected}
          title={t("report")}
          list={[
            L.dailyTransaction,
            L.customerSpendingReport,
            L.transactionPostMonitoringReport,
            L.suspiciousTransactionReport,
          ]}
        />

        <MenuStackWithSubTitle
          selected={selected}
          setSelected={setSelected}
          title={t("dataAnalysis")}
          list={dataAnalysisMenu}
        />
      </div>
      <Box
        sx={{
          textAlign: "center",
          color: COLOR.text.secondary.hex,
          width: "320px",
          position: "fixed",
          left: 0,
          bottom: "12px",
        }}
      >
        {t("version")}: {versionNumber}
      </Box>
    </div>
  );
}

function MenuStack(props: any) {
  const { hasPermission } = usePermission();
  const zusListStore = useZusListStore();
  const resetZusList = () => zusListStore.clear();
  const translate = useTranslate();
  const t = (key: string) => translate(`menu.${key}`);
  const { title, list, selected, setSelected } = props;
  const expanded = selected === title;
  const [selectedPagePathname, setSelectedPagePathname] = useState(
    window.location.pathname.substring(1)
  );
  const toggle = () =>
    setSelected((key: string) => {
      if (key === title) return "";
      return title;
    });
  const canRender = (key: string) => {
    if (key === "*****") return true; //for temporary use
    return hasPermission(key);
  };
  const pendingOperationsCount: {
    merchantOperation: string;
    ledgerDepositRecord: string;
  } = props?.meta?.pendingOperationsCount || {
    merchantOperation: "0",
    ledgerDepositRecord: "0",
  };
  const genItem = ([text, to, permission]: any = []) => {
    return (
      canRender(permission) && (
        <Box sx={{ width: "calc(100% - .5rem)", display: "flex" }} key={text}>
          <MenuItemLink
            to={to}
            primaryText={t(text)}
            sx={{ flex: "1" }}
            onClick={
              selectedPagePathname !== to
                ? () => {
                    resetZusList();
                    setSelectedPagePathname(to);
                  }
                : undefined
            }
          />
        </Box>
      )
    );
  };
  const subMenus = list.map(genItem);
  const isHidden = subMenus.filter((dom: any) => dom).length === 0;
  if (isHidden) return <></>;
  return (
    <Accordion
      sx={{
        color: expanded ? sx.expanded : sx.notExpanded,
        ...sx.muiAccordion,
      }}
      expanded={expanded}
      onChange={toggle}
    >
      <AccordionSummary
        expandIcon={<ExpandCircleDownIcon />}
        aria-controls={title}
        sx={sx.accordionSummary}
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={sx.accordionDetails}>
        <Typography variant="h6">{list.map(genItem)}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
