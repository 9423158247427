import APIs from "../../../../../api";
import { IPartialSearchCardProfileNameResponse } from "../../../../../api/types";

interface IPartialSearchCall {
  cardProfileName: string;
}

type ResponseType = {
  count: number;
  rows: IPartialSearchCardProfileNameResponse[];
};

export const partialSearchCardProfileName = async (
  props: IPartialSearchCall
): Promise<ResponseType> => {
  const { cardProfileName } = props;

  if (!cardProfileName) {
    return [] as any as Promise<ResponseType>;
  }

  const res = await APIs.CreditPortal.partialSearchCardProfileName({
    cardProfileName,
  });

  if (!res) {
    return [] as any as Promise<ResponseType>;
  }

  return res;
};
