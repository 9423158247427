import React, { useEffect, useState } from "react";
import api from "../../../../../../api";
import { Box } from "../../../../../../components/MuiGenerals";
import {
  useAlerting,
  usePermission,
  useTranslation,
} from "../../../../../../hooks";
import useCustomerInfoSearch from "../../../../../../hooks/query/useCustomerInfoSearch";
import useValidation from "../../../../../../hooks/useValidation";
import { ValidationConfigProps } from "../../../../../../hooks/useValidation/types";
import { requiredValidator } from "../../../../../../utils/validators/requiredValidator";
import { PermissionKey, translatePrefix } from "../../../config";
import Form from "./Form";
import ActionButtons from "./ActionButtons";
import { alerting } from "../../../../../../reducer/alerterSlice";
import { appError } from "../../../../../../utils/errorHandler";

interface IContentProps {
  closeDialog: () => void;
  refreshTable: () => void;
}

enum ValidationFieldKeys {
  merchantId = "merchantId",
  programName = "programName",
  cardProfileName = "cardProfileName",
}

const Content: React.FC<IContentProps> = ({ closeDialog, refreshTable }) => {
    const { t, tc, translate } = useTranslation(translatePrefix);
    const { alerting } = useAlerting();
    const { hasPermission } = usePermission(); 
    const initField = {
        merchantId: "",
        programName: "",
        cardProfileName: "",
        e6ProgramName: "",
    };
    const [assignCardProfileFields, setassignCardProfileFields] = useState(initField);
    const { merchant, loading } = useCustomerInfoSearch(
        assignCardProfileFields.cardProfileName
    );

  const validationConfig: ValidationConfigProps = [
    {
      key: ValidationFieldKeys.merchantId,
      value: assignCardProfileFields.merchantId,
      validators: [
        requiredValidator(
          translate("validation.selectRequired", {
            fieldName: t("merchant_name"),
          })
        ),
      ],
    },
    {
      key: ValidationFieldKeys.programName,
      value: assignCardProfileFields.programName,
      validators: [
        requiredValidator(
          translate("validation.selectRequired", {
            fieldName: t("programName"),
          })
        ),
      ],
    },
    {
      key: ValidationFieldKeys.cardProfileName,
      value: assignCardProfileFields.cardProfileName,
      validators: [
        requiredValidator(
          translate("validation.selectRequired", {
            fieldName: t("card_profile_name"),
          })
        ),
      ],
    },
  ];
  const { validate } = useValidation(validationConfig);
  const onConfirm = async () => {
    const { allErrors } = validate();

    if (allErrors.length > 0) {
      return alerting("warning", allErrors[0]);
    }

    const res = await api.Setting.assignCardProfile.postAssign(
      {
        merchantId: parseInt(assignCardProfileFields.merchantId),
        programName: assignCardProfileFields.programName,
        cardProfileName: assignCardProfileFields.cardProfileName,
      },
      {
        isSkipErrorHandling: true,
      }
    );

    // handle custom error msg
    if (res?.error) {
      if (String(res?.error?.code) === "-51") {
        return alerting("warning", t("duplicateAssignCardProfileError"));
      }

      return appError({ ...res?.error, requestId: res?.requestId });
    }

    alerting("success", t("create_request_success"));
    refreshTable();
    closeDialog();
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form
            loading={loading}
            merchant={merchant}
            assignCardProfileFields={assignCardProfileFields}
            setAssignCardProfileFields={setassignCardProfileFields}
          />
          <ActionButtons onConfirm={onConfirm} closeDialog={closeDialog} />
        </Box>
      </Box>
    </>
  );
};

export default Content;
