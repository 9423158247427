import { ReactNode } from "react";
import { useTranslate } from "react-admin";

import { Breakpoint } from "@mui/system";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "./MuiGenerals";

export interface DialogInOneProps {
  title: string;
  self: {
    open: boolean;
    onClose: any;
  };
  content: ReactNode | string;
  onConfirm: () => void;
  onCancel: () => void;
  isConfirmHidden?: boolean;
  isCancelHidden?: boolean;
  actionButtons?: ReactNode;
  isLoadingDialog?: boolean;
  size?: Breakpoint;
  rest?: any;
  dialogHeight?: {
    height?: string;
    maxHeight?: string;
  };
  maxContentHeight?: string;
  customConfirmButtonText?: string;
  customCancelButtonText?: string;
}

export default function DialogInOne(props: DialogInOneProps) {
  const translate = useTranslate();
  const translatePrefix = "common";
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const {
    size = "md",
    self,
    title,
    content,
    onConfirm,
    onCancel,
    isConfirmHidden,
    isCancelHidden,
    actionButtons,
    rest,
    isLoadingDialog,
    dialogHeight,
    maxContentHeight,
    customConfirmButtonText,
    customCancelButtonText,
  } = props;

  return (
    <Dialog
      open={self.open}
      onClose={self.onClose}
      fullWidth={true}
      maxWidth={size}
      PaperProps={{
        sx: {
          height: dialogHeight?.height || "auto",
          maxHeight: dialogHeight?.maxHeight || "none",
        },
      }}
      {...rest}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          padding: `${isLoadingDialog ? "1rem 2.5rem" : "0 2rem"}`,
          ...(maxContentHeight && { maxHeight: maxContentHeight }),
        }}
      >
        {content}
      </DialogContent>
      <DialogActions /* sx={{ justifyContent: "center" }} */>
        {actionButtons}
        {!isConfirmHidden && (
          <Button
            className={"inheritColor"}
            color={"primary"}
            variant={"contained"}
            children={customConfirmButtonText || t("confirm")}
            onClick={onConfirm}
          />
        )}
        {!isCancelHidden && (
          <Button
            className={"inheritColor"}
            color={"warning"}
            variant={"contained"}
            children={customCancelButtonText || t("cancel")}
            onClick={onCancel}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
