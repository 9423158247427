import { FeatureReports } from '@wallet-manager/pfh-node-def-types/dist/src/FeatureCode';

import api from '../../../../api';
import { Ipage } from '../../../../api/types';
import { Iprefix } from '../../../../hooks/useTranslation';
import { initZusParams } from '../../../../utils/config';
import { createZusInstance } from '../../../../zustand/store';

const translatePrefix: Iprefix = "customerSpendingMonthlyReport";

const PermissionKey = FeatureReports.CustomerSpendingReport.MonthlyReportTab;

interface Ifield {
  programAgentId: string;
  distributorAgentId: string;
  pfhNo: string;
  programNames: string[];
}

const initFields: Ifield = {
  programAgentId: "",
  distributorAgentId: "",
  pfhNo: "",
  programNames: [],
};

interface IsearchParam {
  monthFrom: string;
  monthTo: string;
  programAgentId: string;
  distributorAgentId: string;
  customerNumber: string;
  programNames: string[];
}

interface IgetAllRes {
  month: string;
  distributorAgentId: string;
  programAgentId: string;
  customerNumber: string;
  programName: string;
  currency: string;
  repayment: number;
  totalTransactionAmount: number;

  localPayment: number;
  localPaymentPfhCost: number;
  localPaymentPaMarkup: number;

  localWithdraw: number;
  localWithdrawPfhCost: number;
  localWithdrawPaMarkup: number;

  localRefund: number;
  localRefundPfhCost: number;
  localRefundPaMarkup: number;

  overseaPayment: number;
  overseaPaymentPfhCost: number;
  overseaPaymentPaMarkup: number;

  overseaWithdraw: number;
  overseaWithdrawPfhCost: number;
  overseaWithdrawPaMarkup: number;

  overseaRefund: number;
  overseaRefundPfhCost: number;
  overseaRefundPaMarkup: number;

  totalPfhCost: number;
  totalPaMarkup: number;
  annualFee: number;
  visaRebate: number;
  paTotalRebate: number;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
} = Object.freeze({
  export: api.Report.customerSpendingReport.monthlyReport.getExport,
  table: api.Report.customerSpendingReport.monthlyReport.getAll,
});

const translateKeyObj = Object.freeze({
  month: "month",
  programAgentId: "program_agent_id",
  distributorAgentId: "distributor_agent_id",
  distributorAgentName: "distributor_agent_name",
  pfhNo: "pfh_no",
  currency: "currency",
  repayment: "repayment",
  totalTransactionAmount: "total_transaction_amount",
  localPayment: "local_payment",
  localPaymentPfhCost: "local_payment_pfh_cost",
  localPaymentPaMarkup: "local_payment_pa_markup",
  localWithdraw: "local_withdraw",
  localWithdrawPfhCost: "local_withdraw_pfh_cost",
  localWithdrawPaMarkup: "local_withdraw_pa_markup",
  localRefund: "local_refund",
  localRefundPfhCost: "local_refund_pfh_cost",
  localRefundPaMarkup: "local_refund_pa_markup",
  overseasPayment: "overseas_payment",
  overseasPaymentPfhCost: "overseas_payment_pfh_cost",
  overseasPaymentPaMarkup: "overseas_payment_pa_markup",
  overseasWithdraw: "overseas_withdraw",
  overseasWithdrawPfhCost: "overseas_withdraw_pfh_cost",
  overseasWithdrawPaMarkup: "overseas_withdraw_pa_markup",
  overseasRefund: "overseas_refund",
  overseasRefundPfhCost: "overseas_refund_pfh_cost",
  overseasRefundPaMarkup: "overseas_refund_pa_markup",
  totalPfhCost: "total_pfh_cost",
  totalPaMarkup: "total_pa_markup",
  annualFee: "annual_fee",
  visaRebate: "visa_rebate",
  paTotalRebate: "pa_total_rebate",
  settlementDate: "settlement_date",
  programName: "program_name",
});

const omitKeyObj = Object.freeze({
  export: [],
  table: [],
});

export {
  translatePrefix,
  PermissionKey,
  type Ifield,
  type IsearchParam,
  type IgetAllRes,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
};
