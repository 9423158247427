import { useEffect, useState } from "react";

import { DialogInOne, LoadingDialog } from "../../../../../components";
import { DialogInOneProps } from "../../../../../components/DialogInOne";
import { useAlerting, useTranslation } from "../../../../../hooks";
import { useZusDialogStore } from "../../../../../zustand/store";
import { useZusParams } from "../../config";
import Content from "./Content";
import { CreateCardProfileFields } from "./types";
import useCreateCardProfileDialogValidation from "./useCreateCardProfileDialogValidaiton";
import APIs from "../../../../../api";
import { EnumE6CardType } from "@wallet-manager/pfh-node-def-types/dist/src/E6Enum";

const initFields = {
  e6ProgramName: "",
  cardProfileName: "",
  description: "",
  cardType: "" as EnumE6CardType,
  iin: "",
  serviceCode: "",
  iinSegment: "",
  expiry: "",
  embossedName: "",
  pinLength: "",
  panLength: "",
  issuerIdentifier: "",
  productCode: "",
};

export default function CreateCardProfileDialog() {
  const [fields, setFields] = useState<CreateCardProfileFields>(initFields);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const { alerting } = useAlerting();
  const { t } = useTranslation("cardProfileSetting");
  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { validate } = useCreateCardProfileDialogValidation({ fields });

  const resetFields = () => {
    setFields(initFields);
  };

  const handleCloseDialog = () => {
    zusDialog.closeAll();
    resetFields();
  };

  const handleSubmit = async () => {
    const { allErrors } = validate();

    if (allErrors.length > 0) {
      return alerting("warning", allErrors[0]);
    }

    setIsShowLoading(true);
    const res = await APIs.Setting.cardProfileSetting.createCardProfile({
      e6ProgramName: fields.e6ProgramName,
      cardProfileName: fields.cardProfileName,
      serviceCode: fields.serviceCode,
      expiry: Number(fields.expiry),
      embossedName: fields.embossedName,
      cardType: fields.cardType,
      iin: fields.iin,
      iinSegment: fields.iinSegment,
      pinLength: Number(fields.pinLength),
      panLength: Number(fields.panLength),
      description: fields.description,
      schemeId: fields.issuerIdentifier,
      productCode: fields.productCode || "",
    });
    setIsShowLoading(false);

    if (!res) {
      return;
    }

    handleCloseDialog();
    zusParams.refetch();
    alerting("success", t("createProfileSuccessMsg"));
  };

  const dialogConfig: DialogInOneProps = {
    title: t("createCardProfile"),
    self: {
      open: zusDialog.match("createProfileDialog"),
      onClose: handleCloseDialog,
    },

    content: <Content fields={fields} setFields={setFields} />,
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,
    size: "lg",
    dialogHeight: {
      maxHeight: "90dvh",
    },
  };

  return (
    <>
      <LoadingDialog forceOpen={isShowLoading} />
      <DialogInOne {...dialogConfig} />
    </>
  );
}
