import { CardProfileDetails } from "../../../../../api/types";
import DialogInOne, {
  DialogInOneProps,
} from "../../../../../components/DialogInOne";
import { useTranslation } from "../../../../../hooks";
import { useZusDialogStore } from "../../../../../zustand/store";
import { directTranslateCardType } from "../../helpers/getCardTypeDisplayValue";
import Content from "./Content";

export default function DetailsDialog() {
  const { t, tc, translate } = useTranslation("cardProfileSetting");
  const zusDialog = useZusDialogStore();

  const rowData = zusDialog.meta.row;
  const cardProfileDetails = zusDialog.meta
    .cardProfileDetails as CardProfileDetails;

  const handleCloseDialog = () => {
    zusDialog.closeAll();
  };

  const displayCardType = directTranslateCardType(
    cardProfileDetails?.cardtype,
    translate
  );

  const dialogConfig: DialogInOneProps = {
    title: t("detailsDialogTitle"),
    self: {
      open: zusDialog.match("cardProfileDetailsDialog"),
      onClose: handleCloseDialog,
    },
    content: (
      <Content
        e6ProgramName={rowData?.e6ProgramName}
        cardProfileName={rowData?.cardProfileName}
        pansIssued={String(cardProfileDetails?.pansIssued)}
        description={cardProfileDetails?.description}
        cardType={displayCardType}
        iin={cardProfileDetails?.iin}
        serviceCode={cardProfileDetails?.serviceCode}
        iinSegment={cardProfileDetails?.iinSegment}
        expiryNoOfMonths={String(cardProfileDetails?.expiry)}
        embossedName={cardProfileDetails?.embossedName}
        pinLength={String(cardProfileDetails?.pinLength)}
        panLength={String(cardProfileDetails?.panLength)}
        issuerIdentifier={cardProfileDetails?.schemeId}
        productCode={cardProfileDetails?.productCode || ""}
      />
    ),
    onConfirm: () => {},
    isConfirmHidden: true,
    onCancel: handleCloseDialog,
    isLoadingDialog: true,
    size: "sm",
    customCancelButtonText: tc("close"),
  };

  return (
    <>
      {/* <LoadingDialog forceOpen={isShowLoading} /> */}
      <DialogInOne {...dialogConfig} />
    </>
  );
}
