import { ValidatorResponse } from "../../hooks/useValidation/types";

interface IConfig {
  max: number;
}

export const maxValueValidator =
  (errorMessage: string, config: IConfig) =>
  (value: any): ValidatorResponse => {
    const { max } = config;

    const isValueLargerThanMax = Number(value) > Number(max);

    const isInvalid = isValueLargerThanMax;

    if (isInvalid) {
      return { isValid: false, errorMessage };
    }

    return { isValid: true, errorMessage: "" };
  };
