const MTI_CODE: Record<string, string> = {
  "0100": "AuthorizationRequest",
  "0110": "AuthorizationResponse",
  "0120": "AuthorizationAdvice",
  "0121": "AuthorizationAdviceRepeat",
  "0130": "IssuerResponsetoAuthorizationAdvice",
  "0200": "AcquirerFinancialRequest",
  "0210": "IssuerResponsetoFinancialRequest",
  "0220": "AcquirerFinancialAdvice",
  "0221": "AcquirerFinancialAdviceRepeat",
  "0230": "IssuerResponsetoFinancialAdvice",
  "0320": "BatchUpload",
  "0330": "BatchUploadResponse",
  "0400": "AcquirerReversalRequest",
  "0410": "AcquirerReversalResponse",
  "0420": "AcquirerReversalAdvice",
  "0430": "AcquirerReversalAdviceResponse",
  "0510": "BatchSettlementResponse",
  "0800": "NetworkManagementRequest",
  "0810": "NetworkManagementResponse",
  "0820": "NetworkManagementAdvice",
};

export default MTI_CODE;
