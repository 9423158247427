import React from "react";

import { MpTextField } from "../../../../../components";
import { useTranslation } from "../../../../../hooks";

interface IProps {
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
}

const IinTextField = (props: IProps) => {
  const { label, value, onChange } = props;
  const { tc } = useTranslation("common");

  return (
    <MpTextField
      label={label || tc("phInputField", { fieldName: tc("iin") })}
      value={value}
      onChange={onChange}
    />
  );
};

export default IinTextField;
