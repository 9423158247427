import { EnumE6CardType } from "@wallet-manager/pfh-node-def-types/dist/src/E6Enum";
import { SingleSelection } from "../../../../../components";
import { SelectChangeEvent } from "../../../../../components/MuiGenerals";
import { useTranslation } from "../../../../../hooks";
import { getCardTypeDisplayValue } from "../../../../../views/Setting/CardProfileSetting/helpers/getCardTypeDisplayValue";
import { removeEnumKeysByValue } from "../../../../../utils/helper";

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const CardTypeSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { translate, tc } = useTranslation("common");

  return (
    <SingleSelection
      label={label || tc("phSelection", { fieldName: tc("cardType") })}
      value={value || ""}
      clearSelect={onClear}
      onChange={onChange}
      enumData={EnumE6CardType}
      nameFn={(item) => getCardTypeDisplayValue(item, translate)}
      isNoSorting
    />
  );
};

export default CardTypeSingleSelection;
