import { FeatureReports } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import api from "../../../api";
 import { Ipage } from '../../../api/types';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';

const translatePrefix: Iprefix = "suspiciousTransactionReport";
const PermissionKey = FeatureReports.SuspiciousTransactionReport;

interface Ifield {
    ruleName: string;
    programName: string[],
    ruleVersion: string;
    outputDescription: string;
    outputValue: string;
    transactionId: string;
    transactionTime: string; //creation_time in db
    settlementTime: string;
    customerNumber: string;
    cardNumber: string;
    panLast4: string;
    transactionType: string;
    transactionSubType: string;
    paymentType: string;
    authCode: string;
    panlast4: String;
    paymentMethod: string,
    authorizationCode: string,
}

const initFields: Ifield = {
    ruleName: "",
    programName: [],
    ruleVersion: "",
    outputDescription: "",
    outputValue: "",
    transactionId: "",
    transactionTime: "",
    settlementTime: "",
    customerNumber: "",
    cardNumber: "",
    panLast4: "",
    transactionType: "",
    transactionSubType: "",
    paymentType: "",
    authCode: "",
    panlast4: "",
    paymentMethod: "",
    authorizationCode: "",
}

interface IsearchParam {
    programName: string[];
    ruleName: string;
    transactionId: string;
    customerNumber: string;
    cardNumber: string;
    panLast4: string;
    transactionTypes: string[] | undefined;
    transactionSubTypes: string[] | undefined;
    paymentType: string;
    authCode: string;
    createdDateFrom: string;
    createdDateTo: string;
    creationTimeFrom: string;
    creationTimeTo: string;
    settlementDateFrom: string;
    settlementDateTo: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
    initZusParams
);

const apiObj: {
    export: (params: any, config?: any) => any;
    table: (params: any, config?: any) => any;
  } = Object.freeze({
    export: api.Report.suspiciousTransactionReport.getExport,
    table: api.Report.suspiciousTransactionReport.getAll,
  });

  const translateKeyObj = Object.freeze({
    ruleName: "rule_name",
    ruleVersion: "rule_version",
    outputDescription: "output_description",
    outputValue: "output_value",
    transactionId: "transaction_id",
    transactionTime: "transaction_time", //creation_time in d,
    settlementTime: "settlement_time",
    programName: "program_name",
    customerNumber: "customer_number",
    cardNumber: "card_number",
    panLast4: "pan_last_4",
    transactionType: "transaction_type",
    paymentType: "payment_type",
    subType: "sub_type",
    paymentMethod: "payment_method",
    acquirerCurrency: "acquirer_currency",
    acquirerAmount: "acquirer_amount",
    billingCurrency: "billing_currency",
    billingAmount: "billing_amount",
    authorizationCode: "authorization_code",
    onlineTransaction: "online_transaction",
    merchantCategoryCodes: "merchant_category_codes",
    cardAcceptorName: "card_acceptor_name",
    cardAcceptorCountryCode: "card_acceptor_country_code",
    cardAcceptorCity: "card_acceptor_city",
    memo: "memo",
    createdBy: "created_by",
    creationTime: "creation_time",
  });

  const omitKeyObj = Object.freeze({
    export: ["rawData"],
    table: [],
  });

  type TableApiRes = {
    ruleName: string;
    ruleVersion: string;
    outputDesc: string;
    outputValue: string;
    transactionId: number;
    transactionTime: string;
    settlementDate: string;
    programName: string;
    customerNumber: string;
    cardNumber: null;
    panLast4: null;
    paymentType: string;
    paymentMethod: string;
    acquirerCurrency: string;
    acquirerAmount: string;
    billingCurrency: string;
    billingAmount: string;
    authorizationCode: string;
    onlineTransaction: string;
    merchantCategoryCodes: string;
    cardAcceptorName: string;
    cardAcceptorCountryCode: null;
    cardAcceptorCity: null;
    memo: string;
    createdBy: string;
    creationTime: string;
    createdDate: string;
    transactionType: number;
    transactionSubType: number;
    transactionSource: number;
    currency: string;
    amount: number;
    authCode: string;
    isOnline: boolean;
    mcc: string;
  }

  export {
    translatePrefix,
    PermissionKey,
    type Ifield,
    type IsearchParam,
    type TableApiRes,
    initFields,
    initZusParams,
    useZusParams,
    apiObj,
    translateKeyObj,
    omitKeyObj,
  };