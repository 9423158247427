import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";

import { EnumE6CardType } from "@wallet-manager/pfh-node-def-types/dist/src/E6Enum";

import APIs from "../../../../../api";
import { CardProfileDetails } from "../../../../../api/types";
import { LoadingDialog } from "../../../../../components";
import DialogInOne, {
  DialogInOneProps,
} from "../../../../../components/DialogInOne";
import { useAlerting, useTranslation } from "../../../../../hooks";
import { useZusDialogStore } from "../../../../../zustand/store";
import { useZusParams } from "../../config";
import Content from "./Content";
import { EditCardProfileFields } from "./types";
import useEditDialogValidation from "./useEditDialogValidation";

const initFields = {
  description: "",
  cardType: "" as EnumE6CardType,
  iin: "",
  serviceCode: "",
  iinSegments: "",
  expiry: "",
  embossedName: "",
  pinLength: "",
  panLength: "",
  issuerIdentifier: "",
  productCode: "",
};

export default function EditDialog() {
  const [fields, setFields] = useState<EditCardProfileFields>(initFields);
  const [defaultDataSnapshot, setDefaultDataSnapshot] =
    useState<EditCardProfileFields>(initFields);

  const [isShowLoading, setIsShowLoading] = useState(false);

  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const { alerting } = useAlerting();
  const { t, tc } = useTranslation("cardProfileSetting");
  const { validate } = useEditDialogValidation({ fields });

  const rowData = zusDialog.meta.row;
  const cardProfileDetails = zusDialog.meta
    .cardProfileDetails as CardProfileDetails;

  const isDialogOpen = zusDialog.match("editCardProfileDialog");

  const handleCloseDialog = () => {
    zusDialog.closeAll();
    setFields(initFields);
  };

  const isModified = () => {
    // compare the fields with the default data snapshot
    return !isEqual(fields, defaultDataSnapshot);
  };

  const handleConfirm = async () => {
    if (!isModified()) {
      return alerting("warning", t("pleaseModify"));
    }

    const { allErrors } = validate();

    if (allErrors.length > 0) {
      return alerting("warning", allErrors[0]);
    }

    setIsShowLoading(true);
    const res = await APIs.Setting.cardProfileSetting.editCardProfile({
      e6ProgramName: rowData?.e6ProgramName,
      cardProfileName: rowData?.cardProfileName,
      serviceCode: fields.serviceCode,
      expiry: Number(fields.expiry),
      embossedName: fields.embossedName,
      cardType: fields.cardType,
      iin: fields.iin,
      iinSegment: fields.iinSegments,
      schemeId: fields?.issuerIdentifier,
      pinLength: Number(fields.pinLength),
      panLength: Number(fields.panLength),
      description: fields.description,
      productCode: fields.productCode || "",
    });
    setIsShowLoading(false);

    if (!res) return;

    handleCloseDialog();
    zusParams.refetch();
    alerting("success", t("editProfileSuccessMsg"));
  };

  const dialogConfig: DialogInOneProps = {
    title: t("editDialogTitle"),
    self: {
      open: isDialogOpen,
      onClose: handleCloseDialog,
    },
    content: (
      <Content
        fields={fields}
        setFields={setFields}
        rowData={rowData}
        cardProfileDetails={cardProfileDetails}
      />
    ),
    onConfirm: handleConfirm,
    onCancel: handleCloseDialog,
    isLoadingDialog: true,
    size: "lg",
    dialogHeight: {
      maxHeight: "90dvh",
    },
  };

  useEffect(() => {
    if (isDialogOpen) {
      const defaultData = {
        description: rowData.description,
        cardType: rowData.cardType,
        iin: rowData.iin,
        serviceCode: cardProfileDetails?.serviceCode,
        iinSegments: cardProfileDetails?.iinSegment,
        expiry: String(cardProfileDetails?.expiry),
        embossedName: cardProfileDetails?.embossedName,
        pinLength: String(cardProfileDetails?.pinLength),
        panLength: String(cardProfileDetails?.panLength),
        issuerIdentifier: cardProfileDetails?.schemeId,
        productCode: cardProfileDetails?.productCode || "",
      };

      setFields(() => {
        return defaultData;
      });

      setDefaultDataSnapshot(() => {
        return defaultData;
      });
    }
  }, [isDialogOpen, rowData, cardProfileDetails]);

  return (
    <>
      <LoadingDialog forceOpen={isShowLoading} />
      <DialogInOne {...dialogConfig} />
    </>
  );
}
