import { ValidatorResponse } from "../../hooks/useValidation/types";

interface IConfig {
  min: number;
  max: number;
  enable?: boolean;
}

export const requiredTextInputLength =
  (errorMessage: string, config: IConfig) =>
  (value: any): ValidatorResponse => {
    const { min, max, enable = true } = config;

    if (enable && (value.length < min || value.length > max)) {
      return { isValid: false, errorMessage };
    }

    return { isValid: true, errorMessage: "" };
  };
