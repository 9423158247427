import { ChangeEvent, useState } from 'react';

import { Container } from '@mui/system';
import {} from '@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master';
import { EnumDailyTransactionReportType } from '@wallet-manager/pfh-node-def-types/dist/src/DbModel/Statement/Enum/DailyTransactionReportType';

import { SingleSelection } from '../../../components';
import { useDatePicker } from '../../../components/DatePicker';
import { SelectChangeEvent } from '../../../components/MuiGenerals';
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import { Filter } from '../../../features/common/filterTable/types';
import { useTranslation } from '../../../hooks';
import {
  IField,
  initFields,
  initZusParams,
  ISearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';

function ViewFilter({
  setIsSearched,
}: {
  setIsSearched: (isSearched: boolean) => void;
}) {
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<IField>(initFields);
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters({
    isDefaultExpanded: true,
  });

  const DateObj = {
    ProcessingDate: useDatePicker({
      isEndOfSecond: false,
    }),
  };

  const zusParams = useZusParams();

  const getParams: () => ISearchParam = () => {
    const { ...rest } = fields;

    const { start: processingDateFrom, end: processingDateTo } =
      DateObj.ProcessingDate;

    return {
      ...initZusParams,
      ...rest,
      processingDateFrom,
      processingDateTo,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    setIsSearched(true);
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.ProcessingDate.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const filters: Filter[] = [
    {
      labelKey: TK.processingDate,
      filter: <DateObj.ProcessingDate.Picker type="date" />,
    },
    {
      labelKey: TK.settlementService,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.settlementService) })}
          value={fields.settlementService}
          onChange={onChange("settlementService")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, settlementService: "" }))
          }
          nameFn={(name) => name}
          isNoSorting
          enumData={EnumDailyTransactionReportType}
        />
      ),
    },
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <FilterSectionActionRow>
        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>

      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}

export default ViewFilter;
