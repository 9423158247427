import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";

import api from "../../../../../../api";
import { IGetNetworkMessageResponse } from "../../../../../../api/types";
import { OpRedirect, OpView } from "../../../../../../assets/icons";
import { LoadingDialog, OpIconButton } from "../../../../../../components";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab,
} from "../../../../../../components/Layout";
import { Container } from "../../../../../../components/MuiGenerals";
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../../../../hooks";
import { useGenGridCol } from "../../../../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../../../../utils/constant";
import { toDisplayTime } from "../../../../../../utils/helper";
import { customSx } from "../../../../../../utils/styling";
import { useZusDialogStore } from "../../../../../../zustand/store";
import { translateKeyObj as TK, translatePrefix } from "../../../config";
import NetworkMessageRawDialog from "./NetworkMessageRawDialog";
import { useZusParams } from "./ViewFilter";
import MTI_CODE from "../constants/mtiCode";

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();

  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <TableTab>
        <TableList />
      </TableTab>
    </Container>
  );
}

function TableList(props: {}) {
  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();

  const { t, tc } = useTranslation(translatePrefix);
  const listMapping = useListMapping("table");

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: async () => {
      const isFilterNotFullyFilled =
        !zusParams.body.fromDate ||
        !zusParams.body.toDate ||
        !zusParams.body.customerNumber ||
        !zusParams.body.limitResultTo;

      // Only call API if all the filters are filled
      if (isFilterNotFullyFilled) {
        return;
      }

      return await api.CreditManagement.customerAccount.getNetworkMessage({
        startTime: zusParams.body.fromDate,
        endTime: zusParams.body.toDate,
        limit: Number(zusParams.body.limitResultTo),
        merchantId: zusDialog.meta.merchantId,
        customerId: zusParams.body.customerNumber,
      });
    },
  });

  const { results = [], resultCount = 0 } =
    (listRes.data as any as {
      resultCount: number;
      cursor: any;
      nextCursor: any;
      results: IGetNetworkMessageResponse[];
    }) || {};

  const count = resultCount;

  const omitKeys: string[] = [];

  const content: GridRowsProp = listMapping(results, omitKeys);

  const NetworkMessagesCell = (params: GridRenderCellParams<any, any, any>) => {
    const openNetworkMessagesDialog = () => {
      zusDialog.openExtra("networkMessages", {
        rawNetworkMessage: { ...params.row.rawData },
      });
    };

    return (
      <OpIconButton
        title={t("networkMessages")}
        svgUrl={OpRedirect}
        onClick={openNetworkMessagesDialog}
      />
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol("date"),
    useGenGridCol("currency"),
    useGenGridCol("amount"),
    useGenGridCol("type"),
    useGenGridCol("mti"),
    useGenGridCol("success"),
    useGenGridCol("reversal"),
    useGenGridCol("networkMessages", { renderCell: NetworkMessagesCell }),
  ];

  // const checkedPermissionColumns = useCheckColumnsPermission(
  //   columns,
  //   checkPermissionColumnList
  // );

  const TABLE_ROWS_PER_PAGE = 25;

  function paginateData(data: any, itemsPerPage: number, page: number) {
    if (!data || Object.keys(data).length === 0) return [];

    const pages = Math.ceil(data.length / itemsPerPage);
    const paginatedData = [];

    for (let i = 0; i < pages; i++) {
      const start = i * itemsPerPage;
      const end = start + itemsPerPage;
      paginatedData[i] = data.slice(start, end);
    }

    return paginatedData[page];
  }

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;

  return (
    <>
      <NetworkMessageRawDialog />
      <DataGrid
        {...dataGridDefaults}
        rows={paginateData(content, TABLE_ROWS_PER_PAGE, zusParams.body.page)}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
        pageSize={TABLE_ROWS_PER_PAGE}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const listMappingTransform = useListMappingTransform(mode);

  const { tb, translate } = useTranslation(translatePrefix);

  function formatNumber(number: number) {
    const parts = String(number).split(".");

    if (parts.length < 2) {
      // No decimal part, add .00
      return `${number}.00`;
    } else {
      // Has decimal part, add trailing zeros if necessary
      return `${parts[0]}.${parts[1].padEnd(2, "0")}`;
    }
  }

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: any) => {
      //   const decimal = getDecimal(item.currency, currencyList);
      //   const toDisplayAmount = displayAmountCurrying(decimal);
      const capitalizeType =
        item.transactionType?.name.charAt(0).toUpperCase() +
        item.transactionType?.name.slice(1);

      const mtiCodeTranslateKey = MTI_CODE[String(item.mti)];

      const mtiDisplayValue = mtiCodeTranslateKey
        ? `${translate(`mtiCode.${mtiCodeTranslateKey}`)} (${item.mti})`
        : item.mti;

      const paddedAmount = formatNumber(item.acquirerAmount?.amount);

      const mappedResult = [
        ["rawData", item],
        ["date", toDisplayTime(item.creationTime)],
        ["currency", item.acquirerAmount?.currencyCode],
        ["amount", paddedAmount],
        ["type", capitalizeType],
        ["mti", mtiDisplayValue],
        ["success", tb(item.success)],
        ["reversal", tb(item.reversal)],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
