import { useEffect, useState } from 'react';
import api from "../../../../../api";
import { DialogInOne } from "../../../../../components";
import { useTranslation, useAlerting, usePermission, useGetMerchant } from "../../../../../hooks";
import { PermissionKey, translatePrefix, translateKeyObj as TK } from '../../config';
import { ICardData } from "../../types/ICard";
import TerminateCardDialogContent from "./TerminateCardDialogContent";
import { isEmpty } from 'lodash';
import { EnumCardStatus } from '@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master';
import DetailsDialogContent from '../DetailsDialog/DetailDialogContent';

interface TerminateCardDialogProps {
  title: string;
  isOpen: boolean;
  closeDialog: () => void;
  refreshTable: () => void;
  cardData: ICardData;
}

const TerminateCardDialog: React.FC<TerminateCardDialogProps> = ({
  title,
  isOpen,
  cardData,
  refreshTable,
  closeDialog
}) => {

  const merchantMap = useGetMerchant();

  const [iTerminateInput, setITerminateInput] = useState<ITerminateInput>({
    merchantId: Number(merchantMap[cardData?.merchant_name]),
    customerNumber: cardData?.customer_no,
    cardNumber: cardData?.card_no,
    memo: ''
  })


  const { t, tc, translate } = useTranslation(translatePrefix);

  const { hasPermission } = usePermission();
  const hasPermissionToTerminate = hasPermission(PermissionKey.Terminate);

  const { alerting } = useAlerting();


  const updateField = (fieldName: string, value: string | boolean | number) => {
    setITerminateInput((f: any) => ({ ...f, [fieldName]: value }));
  };

  const onSubmit = async () => {

    if (!hasPermissionToTerminate)
      return alerting("warning", tc("alert_no_permission"));

    if (isEmpty(iTerminateInput.memo))
      return alerting("warning", translate("validation.inputRequired", {
        fieldName: t(TK.terminationReason),
      }));

    const data: ITerminateInput = {
      merchantId: Number(merchantMap[cardData?.merchant_name]),
      customerNumber: cardData?.customer_no,
      cardNumber: cardData?.card_no,
      memo: iTerminateInput.memo
    }

    const res = await api.CreditManagement.cardList.postTerminateCard(data)

    if (!res) {
      return;
    }

    alerting("success", t(TK.terminateSuccess));
    refreshTable()
    closeDialog();
  }

  useEffect(() => {
    setITerminateInput({
      merchantId: Number(merchantMap[cardData?.merchant_name]),
      customerNumber: cardData?.customer_no,
      cardNumber: cardData?.card_no,
      memo: ''
    })
  }, [cardData])

  const dialogConfig = {
    title,
    self: {
      open: isOpen,
      onClose: () => closeDialog()
    },
    content: <TerminateCardDialogContent
      cardData={cardData}
      updateField={updateField}
      iTerminateInput={iTerminateInput}
    />,
    onConfirm: async () => await onSubmit(),
    onCancel: () => closeDialog(),
    isLoadingDialog: true,
    isConfirmHidden: false,
    size: "md" as any,
  }

  return (
    <DialogInOne {...dialogConfig} />
  )
}

export default TerminateCardDialog