import { useTranslation } from "../../../../../hooks";
import useValidation from "../../../../../hooks/useValidation";
import { ValidationConfigProps } from "../../../../../hooks/useValidation/types";
import { maxValueValidator } from "../../../../../utils/validators/maxValueValidator";
import { requiredTextInputLength } from "../../../../../utils/validators/requiredTextInputLength";
import { requiredValidator } from "../../../../../utils/validators/requiredValidator";
import { EditCardProfileFields } from "./types";

interface IProps {
  fields: EditCardProfileFields;
}

const useEditDialogValidation = ({ fields }: IProps) => {
  const { t, translate } = useTranslation("cardProfileSetting");

  const validationConfig: ValidationConfigProps = [
    {
      key: "description",
      value: fields.description,
      validators: [
        requiredValidator(
          translate("validation.inputRequired", {
            fieldName: t("description"),
          })
        ),
      ],
    },
    {
      key: "cardType",
      value: fields.cardType,
      validators: [
        requiredValidator(
          translate("validation.selectRequired", {
            fieldName: t("cardType"),
          })
        ),
      ],
    },
    {
      key: "iin",
      value: fields.iin,
      validators: [
        requiredValidator(
          translate("validation.selectRequired", {
            fieldName: t("iin"),
          })
        ),
      ],
    },
    {
      key: "serviceCode",
      value: fields.serviceCode,
      validators: [
        requiredValidator(
          translate("validation.inputRequired", {
            fieldName: t("serviceCode"),
          })
        ),
      ],
    },
    {
      key: "iinSegment",
      value: fields.iinSegments,
      validators: [
        requiredValidator(
          translate("validation.inputRequired", {
            fieldName: t("iinSegment"),
          })
        ),
      ],
    },
    {
      key: "expiry",
      value: fields.expiry,
      validators: [
        requiredValidator(
          translate("validation.inputRequired", {
            fieldName: t("expiryNoOfMonths"),
          })
        ),
      ],
    },
    {
      key: "embossedName",
      value: fields.embossedName,
      validators: [
        requiredTextInputLength(
          translate("validation.atLeastMinChar", {
            min: 2,
            fieldName: t("embossedName"),
          }),
          {
            min: 2,
            max: 26,
            enable: Boolean(
              fields.embossedName && fields.embossedName.length > 0
            ),
          }
        ),
      ],
    },
    {
      key: "pinLength",
      value: fields.pinLength,
      validators: [
        requiredValidator(
          translate("validation.inputRequired", {
            fieldName: t("pinLength"),
          })
        ),
        maxValueValidator(
          translate("validation.maxValueAlert", {
            fieldName: t("pinLength"),
            max: 127,
          }),
          { max: 127 }
        ),
      ],
    },
    {
      key: "panLength",
      value: fields.panLength,
      validators: [
        requiredValidator(
          translate("validation.selectRequired", {
            fieldName: t("panLength"),
          })
        ),
      ],
    },
  ];

  return useValidation(validationConfig);
};

export default useEditDialogValidation;
