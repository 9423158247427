import { ChartDataType } from "../../../features/DataAnalysis/components/ChartBoard";
import { roundDownToDecimal } from "../../../utils/helper";

export const roundDownAmount = (
  value: string | number,
  dataType?: ChartDataType
) => {
  if (dataType === ChartDataType.AMOUNT) return roundDownToDecimal(value, 2);

  return value;
};
