import React from "react";

import { MpTextField } from "../../../../../components";
import { useTranslation } from "../../../../../hooks";
import {
  checkIsChineseCharacter,
  checkIsWhiteListedSpecialCharacter,
} from "../../../../../utils/helper";

interface IProps {
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
}

const IssuerIdentifierTextFields = (props: IProps) => {
  const { label, value, onChange } = props;

  const { tc } = useTranslation("common");

  const handleOnChange = (e: React.ChangeEvent<any>) => {
    const value = e.target.value;

    if (
      !checkIsWhiteListedSpecialCharacter(value, []) ||
      checkIsChineseCharacter(value)
    )
      return;

    onChange && onChange(e);
  };

  return (
    <MpTextField
      label={label || tc("phInputField", { fieldName: tc("issuerIdentifier") })}
      value={value}
      onChange={handleOnChange}
      inputProps={{ maxLength: 10 }}
    />
  );
};

export default IssuerIdentifierTextFields;
