import React from "react";

import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from "../../../../../components/Layout/DialogContentLayout";
import CardTypeSingleSelection from "../../../../../features/common/filterTable/components/Filters/CardTypeSingleSelection";
import DescriptionTextField from "../../../../../features/common/filterTable/components/Filters/DescriptionTextField";
import EmbossedNameTextField from "../../../../../features/common/filterTable/components/Filters/EmbossedNameTextField";
import ExpiryTextField from "../../../../../features/common/filterTable/components/Filters/ExpiryTextField";
import IinSegmentTextField from "../../../../../features/common/filterTable/components/Filters/IinSegmentTextField";
import IinSingleSelection from "../../../../../features/common/filterTable/components/Filters/IinSingleSelection";
import IssuerIdentifierTextFields from "../../../../../features/common/filterTable/components/Filters/IssuerIdentifierTextFields";
import PanLengthSingleSelection from "../../../../../features/common/filterTable/components/Filters/PanLengthSingleSelection";
import PinLengthTextField from "../../../../../features/common/filterTable/components/Filters/PinLengthTextField";
import ProductCodeTextField from "../../../../../features/common/filterTable/components/Filters/ProductCodeTextField";
import ServiceCodeTextField from "../../../../../features/common/filterTable/components/Filters/ServiceCodeTextField";
import { useTranslation } from "../../../../../hooks";
import { EditCardProfileFields } from "./types";
import { CardProfileDetails } from "../../../../../api/types";
import DialogRow from "../../layout/DialogRow";
import DialogWrapper from "../../layout/DialogWrapper";

interface IRowData {
  [key: string]: any;
  e6ProgramName: string;
  cardProfileName: string;
  pansIssued: number;
}

interface IProps {
  fields: EditCardProfileFields;
  setFields: React.Dispatch<React.SetStateAction<EditCardProfileFields>>;
  rowData: IRowData;
  cardProfileDetails: CardProfileDetails;
}

const Content = (props: IProps) => {
  const { fields, setFields, rowData, cardProfileDetails } = props;

  const { t, tc } = useTranslation("cardProfileSetting");

  const updateField = (key: keyof EditCardProfileFields, value: any) => {
    setFields((fields) => ({ ...fields, [key]: value }));
  };

  return (
    <div>
      <Container>
        <DialogWrapper>
          <DialogRow>
            <RowHeader>{t("e6ProgramName")}</RowHeader>
            <RowContent>{rowData?.e6ProgramName}</RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("cardProfileName")}</RowHeader>
            <RowContent>{rowData?.cardProfileName}</RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("pansIssued")}</RowHeader>
            <RowContent
              contentStyle={{ display: "flex", alignItems: "center" }}
            >
              {cardProfileDetails?.pansIssued}
            </RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("description")}</RowHeader>
            <RowContent>
              <DescriptionTextField
                value={fields.description}
                onChange={(e) => updateField("description", e.target.value)}
              />
            </RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("cardType")}</RowHeader>
            <RowContent>
              <CardTypeSingleSelection
                value={fields.cardType}
                onChange={(e) => updateField("cardType", e.target.value)}
                onClear={() => updateField("cardType", "")}
              />
            </RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("iin")}</RowHeader>
            <RowContent>
              <IinSingleSelection
                value={fields.iin}
                onChange={(e) => updateField("iin", e.target.value)}
                onClear={() => updateField("iin", "")}
              />
            </RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("serviceCode")}</RowHeader>
            <RowContent>
              <ServiceCodeTextField
                value={fields.serviceCode}
                onChange={(e) => updateField("serviceCode", e.target.value)}
              />
            </RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("iinSegment")}</RowHeader>
            <RowContent>
              <IinSegmentTextField
                value={fields.iinSegments}
                onChange={(e) => updateField("iinSegments", e.target.value)}
              />
            </RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("expiryNoOfMonths")}</RowHeader>
            <RowContent>
              <ExpiryTextField
                value={fields.expiry}
                onChange={(e) => updateField("expiry", e.target.value)}
              />
            </RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("embossedName")}</RowHeader>
            <RowContent>
              <EmbossedNameTextField
                label={tc("optional")}
                value={fields.embossedName}
                onChange={(e) => updateField("embossedName", e.target.value)}
              />
            </RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("pinLength")}</RowHeader>
            <RowContent>
              <PinLengthTextField
                value={fields.pinLength}
                onChange={(e) => updateField("pinLength", e.target.value)}
              />
            </RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("panLength")}</RowHeader>
            <RowContent>
              <PanLengthSingleSelection
                value={fields.panLength}
                onChange={(e) => updateField("panLength", e.target.value)}
                onClear={() => updateField("panLength", "")}
              />
            </RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("issuerIdentifier")}</RowHeader>
            <RowContent>
              <IssuerIdentifierTextFields
                label={tc("optional")}
                value={fields.issuerIdentifier}
                onChange={(e) =>
                  updateField("issuerIdentifier", e.target.value)
                }
              />
            </RowContent>
          </DialogRow>
          <DialogRow>
            <RowHeader>{t("productCode")}</RowHeader>
            <RowContent>
              <ProductCodeTextField
                label={tc("optional")}
                value={fields.productCode}
                onChange={(e) => updateField("productCode", e.target.value)}
              />
            </RowContent>
          </DialogRow>
        </DialogWrapper>
      </Container>
    </div>
  );
};

export default Content;
