import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import { FeatureSetting } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import APIs from "../../../api";
import { LoadingDialog } from "../../../components";
import { ButtonMenu, ExportBtn } from "../../../components/Button";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab,
} from "../../../components/Layout";
import { Container } from "../../../components/MuiGenerals";
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../hooks";
import { useGenGridCol } from "../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../utils/constant";
import {
  displayAmountCurrying,
  downloadFiles,
  getDecimal,
  getFullApiResponse,
  toDisplayTime,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import { useZusDialogStore } from "../../../zustand/store";
import {
  apiObj as api,
  omitKeyObj,
  TableApiResType,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import DetailsDialog from "./components/DetailsDialog";
import EditDialog from "./components/EditDialog";
import { directTranslateCardType } from "./helpers/getCardTypeDisplayValue";

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const { hasPermission } = usePermission();
  const listMapping = useListMapping("export");

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }

    const apiFn = (page: number, pageSize: number, signal: any) =>
      APIs.Setting.cardProfileSetting.getExport(
        { ...zusParams.body, page, pageSize },
        { signal }
      );

    const rawRes = await getFullApiResponse(apiFn, count, true);

    if (rawRes.length === 0) return;

    const omitKeys = omitKeyObj.export;
    const res = listMapping(rawRes, omitKeys);
    const config = {};

    downloadFiles(`Card Profile Setting`, res, config);
  };
  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(FeatureSetting.CardProfileSetting.Export)}
        onExport={onExport}
      />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const [isLoading, setIsLoading] = useState(false);

  const { hasPermission } = usePermission();

  const { t, tc } = useTranslation(translatePrefix);
  const listMapping = useListMapping("table");

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => APIs.Setting.cardProfileSetting.getAll(zusParams.body),
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;

  const content: GridRowsProp = listMapping(rows, omitKeys);

  // const refreshTable = () => {
  //   zusParams.refetch();
  // };

  const renderOperationCell = (params: GridRenderCellParams<any, any, any>) => {
    const getCardProfileDetails = async () => {
      setIsLoading(true);
      const res = await APIs.Setting.cardProfileSetting.getCardProfileDetails({
        e6ProgramName: params.row.e6ProgramName,
        cardProfileName: params.row.cardProfileName,
      });
      setIsLoading(false);

      return res;
    };

    const handleDetailsClick = async () => {
      const cardProfileDetails = await getCardProfileDetails();

      zusDialog.open("cardProfileDetailsDialog", {
        row: params.row.rawData,
        cardProfileDetails,
      });
    };

    const handleEditClick = async () => {
      const cardProfileDetails = await getCardProfileDetails();

      zusDialog.open("editCardProfileDialog", {
        row: params.row.rawData,
        cardProfileDetails,
      });
    };

    return (
      <ButtonMenu
        title={t("operation")}
        options={[
          {
            name: t("details"),
            onClickFn: handleDetailsClick,
            isDisabled: false,
            noShow: !hasPermission(FeatureSetting.CardProfileSetting.Details),
          },
          {
            name: t("edit"),
            onClickFn: handleEditClick,
            isDisabled: false,
            noShow: !hasPermission(FeatureSetting.CardProfileSetting.Edit),
          },
        ]}
      />
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, {
      renderCell: renderOperationCell,
      minWidth: 112,
      hide:
        !hasPermission(FeatureSetting.CardProfileSetting.Details) &&
        !hasPermission(FeatureSetting.CardProfileSetting.Edit),
    }),
    useGenGridCol(TK.e6ProgramName),
    useGenGridCol(TK.cardProfileName),
    useGenGridCol(TK.description),
    useGenGridCol(TK.cardType),
    useGenGridCol(TK.iin),
    useGenGridCol(TK.iinSegment),
    useGenGridCol(TK.createdBy),
    useGenGridCol(TK.creationTime),
    useGenGridCol(TK.lastModifiedBy),
    useGenGridCol(TK.lastModifiedTime),
  ];

  useEffect(() => setCount(count), [listRes]);

  return (
    <>
      <LoadingDialog forceOpen={listRes.isLoading || isLoading} />
      <DetailsDialog />
      <EditDialog />

      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const listMappingTransform = useListMappingTransform(mode);

  const { t, translate } = useTranslation("enumConstants");

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: TableApiResType) => {
      // const decimal = getDecimal(item.currency, currencyList);
      // const toDisplayAmount = displayAmountCurrying(decimal);

      const mappedResult = [
        ["rawData", item],
        [TK.e6ProgramName, item.e6ProgramName],
        [TK.cardProfileName, item.cardProfileName],
        [TK.description, item.description],
        [TK.cardType, directTranslateCardType(item.cardType, translate)],
        [TK.iin, item.iin],
        [TK.iinSegment, item.iinSegment.join(", ")],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedBy, item.modifiedBy],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key]) => !omitKeys.includes(key as string));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
