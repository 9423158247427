import React, { ReactNode } from 'react';
import { Box } from '../../../../../../components/MuiGenerals';

interface ICategoryProps {
  title: string;
  children: JSX.Element;
  buttonMenu?: ReactNode
}

const Category: React.FC<ICategoryProps> = ({ title, children, buttonMenu }) => {
  return (
    <Box sx={{
      margin: '1rem',
      marginBottom: '3rem'
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Box sx={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
        }}>
          {title}
        </Box>
        {buttonMenu}
      </Box>
      <Box sx={{
        borderBottom: 'solid 1px #E1E4EA',
        marginBottom: '1rem',
        marginTop: '1rem',
      }}></Box>
      {children}
    </Box>
  );
};

export default Category;
