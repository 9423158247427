import { Box, styled } from "@mui/material";
import { COLOR } from "../../../../../../utils/config";

export const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '1rem',
  [theme.breakpoints.down('md')]: {
    gap: '2rem',  
  }
}));

export const Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minWidth: 'calc(200px - 0.5rem)'
}));

export const WhiteTag = styled(Box)(({ theme }) => ({
  fontSize: '1.2rem'
}));

export const LargePrice = styled(Box)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: COLOR.text.primary.hex,
  marginTop: '0.8rem',
}));

export const SmallTextRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '0.8rem',
  gap: '0.5rem',
  fontSize: '0.7rem',
  color: "rgba(255, 255, 255, 0.5)",
}));