import CreditManagement from "./CreditManagement";
import CreditPortal from "./CreditPortal";
import Report from "./Report";
import DataAnalysis from "./DataAnalysis";
import Setting from "./Setting";

export default {
  CreditPortal,
  CreditManagement,
  Report,
  DataAnalysis,
  Setting,
};
