import { useQuery } from "react-query";

import api from "../../api";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import {
    selectRuleList,
    setRuleList
} from "../../reducer/ruleListSlice";

export async function useFetchRule() {
    const dispatch = useAppDispatch();

    useQuery("ruleList", api.CreditPortal.getRuleList, {
        staleTime: 60*60*12,
        onSuccess: (dbRes) => {
            dispatch(setRuleList(dbRes));
        }
    })
}

export function useGetRule() {
    const ruleList = useAppSelector(selectRuleList);
    return ruleList.list;
}