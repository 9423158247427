import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IruleList } from "../api/types"; 
import { RootState } from "./store";

const initialState: { list: Record<string, string | number> } = {
    list: {},
};

export const ruleListSlice = createSlice({
    name: "ruleList",
    initialState,
    reducers: {
        setRuleList: (state, action: PayloadAction<any[]>) => {
            if (!action.payload) {
                return;
            }
            state.list = action.payload.reduce(
                (acc: Record<string, string | number>, { name }) => ({
                    ...acc,
                    [name]: name,
                }),
                {}
            );
        }
    }
})

export const { setRuleList } = ruleListSlice.actions;

export const selectRuleList = (state: RootState) => state.ruleList;
export default ruleListSlice.reducer;