import React from 'react';

import { Box, Button } from "../../../../../components/MuiGenerals";
import { customSx } from "../../../../../utils/styling";

interface ICreateCardOrderRequestProps{
    label: string;
    isShow: boolean;
    onClick: () => void;
}

const AssignCardProfileRequestButton:React.FC<ICreateCardOrderRequestProps> = ({
    label,
    isShow,
    onClick
})=>{
    return (
        <>
            {
                isShow && (
                    <Box sx={customSx.createRequestBtn}>
                        <Button onClick={onClick} color={"secondary"} variant={"outlined"}>
                            {label}
                        </Button>
                    </Box>
                )
            }
        </>
    )
}

export default AssignCardProfileRequestButton;
