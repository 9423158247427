import { useState } from "react";
import { useRedirect } from "react-admin";

import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Box, Button, Typography } from "@mui/material";

import { forceLogout } from "../../api/axiosInstance";
import { OpSettingComponent } from "../../assets/icons";
import {
  useAccountSetting,
  useAutoLogout,
  useFetchMerchant,
  useFetchPartner,
  useFetchProgram,
  useFetchRule,
  useIsActive,
  useTranslation,
  useYubikeyDetail,
} from "../../hooks";
import useCurrencyList from "../../hooks/useCurrencyList";
import { useAppSelector } from "../../reducer/hooks";
import { selectProfile } from "../../reducer/profileSlice";
import { selectStuff } from "../../reducer/stuffSlice";
import { COLOR } from "../../utils/config";
import { enumTimezone } from "../../utils/constant";
import { enumMapping } from "../../utils/helper";
import { Content, ContentWithSidebar } from "../../utils/styling";
import { LanguageSelection } from "../../views/AccountSetting";
import { OpIconButtonAdjustable } from "../Button";
import Menu from "./Menu";
import SideMenu from "./SideMenu";
import { useFetchE6Program } from "../../hooks/query/useE6Program";

const Enum = {
  timezones: enumMapping(enumTimezone),
};
const sx: Record<string, any> = {
  AppBarSpan: {
    display: "flex",
    backgroundColor: "white",
  },
  SideBarParent: {
    minHeight: "100vh",
    zIndex: 2,
  },
  topRightBar: {
    position: "absolute",
    height: "28px",
    top: "1rem",
    right: ".5rem",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    "& Button.MuiButton-text": {
      fontSize: "1rem",
    },
  },
  topLeftBar: {
    position: "absolute",
    top: "1rem",
    left: "345px",
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    padding: "6px 8px",
  },
  pageName: {
    position: "absolute",
    top: "4.5rem",
    left: "345px",
    padding: "6px 8px",
  },
  email: {
    color: COLOR.text.primary.hex,
    fontSize: "1rem",
  },
  logoutBtn: {
    height: "100%",
    color: COLOR.text.primary.hex,
    backgroundColor: COLOR.layoutBar.primary.hex,
    borderRadius: "30px 29.5px 29.5px 30px",
    padding: ".3rem 7px .3rem 10px",
    boxShadow: "none",
    marginRight: "8px",
    ">span": {
      marginRight: "4px",
    },
    svg: { color: `${COLOR.text.primary.hex} !important` },
  },
  appName: {
    // color: "gray",
    fontSize: "1rem",
  },
} as const;

export default function LayoutBar(props: any) {
  const [isSideMenuCollapse, setIsSideMenuCollapse] = useState(false);

  const { t, te, tc } = useTranslation("menu");
  const { appName, email, timezone } = useAppSelector(selectProfile);
  const { viewPermission } = useAppSelector(selectStuff);
  const redirect = useRedirect();

  const timezoneDisplay = `GMT${te(Enum.timezones[timezone])}`;

  const logoutFn = () => {
    forceLogout("", { from: "menu-button" });
  };
  useAutoLogout();
  useIsActive();
  useCurrencyList({ shouldFetch: true });
  useFetchMerchant();
  useFetchPartner();
  useFetchProgram();
  useFetchE6Program();
  useFetchRule();
  useAccountSetting(); // only call once

  useYubikeyDetail(); // first time call for cache

  const onCollapseClick = () => {
    setIsSideMenuCollapse(!isSideMenuCollapse);
  };

  const getTopMenuHeaderStyle = () => {
    return {
      position: "absolute",
      left: isSideMenuCollapse ? "106px" : "345px",
      transition: "left 0.3s 0.1s cubic-bezier(0.0, 0.0, 0.2, 1)",
    };
  };

  const topMenuHeaderStyle = getTopMenuHeaderStyle();

  return (
    <ContentWithSidebar>
      <div style={sx.SideBarParent}>
        <SideMenu
          isCollapse={isSideMenuCollapse}
          onCollapseClick={onCollapseClick}
        >
          <Menu />
        </SideMenu>
      </div>
      <Box style={{ ...sx.topLeftBar, ...topMenuHeaderStyle }}>
        <Typography variant="caption" sx={sx.appName}>
          {appName}
        </Typography>
      </Box>
      <Box sx={sx.topRightBar}>
        <LanguageSelection />
        <Box
          onClick={() => redirect("/accountSetting")}
          sx={{
            padding: ".3rem .7rem",
            backgroundColor: COLOR.layoutBar.primary.hex,
            borderRadius: ".5rem",
            cursor: "pointer",
            height: "100%",
          }}
        >
          {timezoneDisplay}
        </Box>
        <Typography
          variant="caption"
          sx={{
            fontSize: "1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginRight: "4px" }}>{tc("welcome")},</Box>
          <Box sx={sx.email}>{email}</Box>
          <Box sx={{ marginLeft: "8px" }}>
            <OpIconButtonAdjustable
              title={""}
              size={"1rem"}
              SvgElement={OpSettingComponent}
              onClick={() => redirect("/accountSetting")}
              sxSVG={{
                fill: COLOR.text.secondary.hex,
                path: {
                  fill: COLOR.text.secondary.hex,
                },
              }}
            />
          </Box>
        </Typography>
        <Button
          variant="contained"
          startIcon={<PowerSettingsNewIcon />}
          onClick={logoutFn}
          sx={sx.logoutBtn}
        >
          {tc("logout")}
        </Button>
      </Box>
      <div style={{ ...sx.pageName, ...topMenuHeaderStyle }}>
        <Typography variant="h6">
          {viewPermission && t(viewPermission)}
        </Typography>
      </div>
      <Content>{props.children}</Content>
    </ContentWithSidebar>
  );
}
