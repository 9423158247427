import { FeatureSetting } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import api from "../../../api";
import { Ipage } from "../../../api/types";
import { Iprefix } from "../../../hooks/useTranslation";
import { initZusParams } from "../../../utils/config";
import { createZusInstance } from "../../../zustand/store";
import { object } from "prop-types";

const translatePrefix: Iprefix = "assignCardProfile";
const PermissionKey = FeatureSetting.AssignCardProfile;

interface Ifield {
    merchantId: string;
    programName: string;
    cardProfileName: string;
    status: string;
}

const initFields: Ifield = {
    merchantId: "",
    programName: "",
    cardProfileName: "",
    status: "",
}

interface IsearchParam {
    merchantId: string;
    programName: string;
    cardProfileName: string;
    status: string;
    createdDateFrom: string;
    createdDateTo: string;
    lastModifiedDateFrom: string;
    lastModifiedDateTo: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
    initZusParams
)

const apiObj: {
    export: (params: any, config?: any) => any;
    table: (params: any, config?: any) => any;
} = Object.freeze({
    export: api.Setting.assignCardProfile.getExport,
    table: api.Setting.assignCardProfile.getAll
});

const translateKeyObj = Object.freeze({
    assignCardProfile: "assign_card_profile",
    operation: "operations",
    merchantName: "merchant_name",
    merchantId: "merchant_id",
    programName: "program_name",
    cardProfileName: "card_profile_name",
    status: "status",
    createdBy: "created_by",
    creationTime: "creation_time",
    lastModifiedBy: "last_modified_by",
    lastModifiedTime: "last_modified_time",
})

const omitKeyObj = Object.freeze({
    export: ["rawData"],
    table: [],
});

export {
    translatePrefix,
    PermissionKey,
    type Ifield,
    type IsearchParam,
    initFields,
    initZusParams,
    useZusParams,
    apiObj,
    translateKeyObj,
    omitKeyObj,
};