import { useState } from "react";

import { FeatureSetting } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

import { useDatePicker } from "../../../components/DatePicker";
import { Button, Container } from "../../../components/MuiGenerals";
import CollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters";
import ToggleFilterButton from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton";
import useCollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters";
import E6ProgramNameMultipleSelection from "../../../features/common/filterTable/components/Filters/E6ProgramNameMultipleSelection";
import IinTextField from "../../../features/common/filterTable/components/Filters/IinTextField";
import FilterSectionActionRow from "../../../features/common/filterTable/layout/FilterSectionActionRow";
import { Filter } from "../../../features/common/filterTable/types";
import CardProfileNameAutoComplete from "../../../features/Setting/components/CardProfileNameAutoComplete";
import { usePermission, useTranslation } from "../../../hooks";
import { useZusDialogStore } from "../../../zustand/store";
import {
  Ifield,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import CreateCardProfileDialog from "./components/CreateCardProfileDialog";

function ViewFilter() {
  const [fields, setFields] = useState<Ifield>(initFields);

  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();
  const { t, tc } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const zusParams = useZusParams();

  const getParams: () => IsearchParam = () => {
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedFrom, end: lastModifiedTo } =
      DateObj.LastModifiedTime;

    return {
      ...initZusParams,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom: lastModifiedFrom,
      lastModifiedDateTo: lastModifiedTo,
      e6ProgramName: fields.e6ProgramName,
      cardProfileName: fields.cardProfileName,
      iin: fields.iin,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const updateField = (key: string, value: any) => {
    setFields((fields) => ({ ...fields, [key]: value }));
  };

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.lastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.e6ProgramName,
      filter: (
        <E6ProgramNameMultipleSelection
          value={fields.e6ProgramName}
          onChange={(value) => updateField("e6ProgramName", value)}
          onClear={() =>
            setFields((fields) => ({ ...fields, e6ProgramName: [] }))
          }
        />
      ),
    },
    {
      labelKey: TK.cardProfileName,
      filter: (
        <CardProfileNameAutoComplete
          value={fields.cardProfileName}
          customOnInputChange={(value) => updateField("cardProfileName", value)}
        />
      ),
    },
    {
      labelKey: TK.iin,
      filter: (
        <IinTextField
          value={fields.iin}
          onChange={(e) => updateField("iin", e.target.value)}
        />
      ),
    },
  ];

  const openCreateProfileDialog = () => zusDialog.open("createProfileDialog");

  const CreateCardProfileButton = () => {
    if (!hasPermission(FeatureSetting.CardProfileSetting.CreateProfile))
      return <></>;

    return (
      <Button variant="outlined" onClick={openCreateProfileDialog}>
        {t("createCardProfile")}
      </Button>
    );
  };

  return (
    <Container disableGutters maxWidth={false}>
      <CreateCardProfileDialog />

      <FilterSectionActionRow>
        <CreateCardProfileButton />

        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>

      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onReset={onReset}
        onSearch={onSearch}
      />
    </Container>
  );
}

export default ViewFilter;
