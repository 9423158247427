export function programInfoSearchReturnText(programName: any | null, merchantId: any | null , merchantProgram: any | null) {
   
    let returnObj = { e6ProgramName: "" }
    if (merchantProgram && programName && merchantId) { //merchantList.e6ProgramName
        const result = getE6ProgramName(programName, merchantId, merchantProgram.rawData)
        returnObj.e6ProgramName = result//merchant.e6ProgramName;
    }
    return returnObj;
}


function getE6ProgramName(searchTerm: string, merchantId: number, rawData: any) {
    var result = '';
    for (const program of rawData) {
        if (program['programName'] == searchTerm && program['merchantId'] == merchantId) {
            result = program['e6_program_name'];
            return result
        }
    }
    return 'not found';
}