import { DialogInOne, OpIconButton, OpTextButton } from "../../../components";
import { DialogInOneProps } from "../../../components/DialogInOne";
import { usePermission, useTranslation } from "../../../hooks";
import { useZusDialogStore } from "../../../zustand/store";
import {
  apiObj as api,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
} from "./config";
import PermissionTab from "../../../components/Layout/PermissionTab";
import { Iprefix } from "../../../hooks/useTranslation";
import DetailTabContent from "./components/DetailTabContent";
import FinancialTabContent from "./components/FinancialTabContent";
import NetworkMessageTabContent from "./components/NetworkMessageTabContent";
import MonthlyStatementTabContent from "./components/MonthlyStatementTablContent";

export default function OperationDialog() {
  const { tc, t } = useTranslation("customerAccount");
  const zusDialog = useZusDialogStore();

  const handleCloseDialog = () => {
    zusDialog.close();
  };

  const CloseBtnEle = () => (
    <OpTextButton text={tc("close")} onClick={handleCloseDialog} />
  );

  const dialogConfig: DialogInOneProps = {
    title: /* tc("details") */ "",
    self: {
      open: zusDialog.match("operationDialog"),
      onClose: handleCloseDialog,
    },

    content: (
      <PermissionTab
        contentArr={[
          {
            translatePrefix: `${translatePrefix}` as Iprefix,
            element: <DetailTabContent />,
          },
          {
            translatePrefix: `${translatePrefix}.offering_tab` as Iprefix,
            element: <FinancialTabContent />,
          },
          {
            translatePrefix: `${translatePrefix}.network_tab` as Iprefix,
            element: <NetworkMessageTabContent />,
          },
          {
            translatePrefix: `${translatePrefix}.monthly_statement` as Iprefix,
            element: <MonthlyStatementTabContent />,
          },
        ]}
        tabsArr={[
          {
            name: t("details"),
            value: `${translatePrefix}` as Iprefix,
            permissionCode: PermissionKey.Details.prefix,
          },
          {
            name: t("offering"),
            value: `${translatePrefix}.offering_tab` as Iprefix,
            permissionCode: PermissionKey.Details.OfferingTab,
          },
          {
            name: t("network"),
            value: `${translatePrefix}.network_tab` as Iprefix,
            permissionCode: PermissionKey.Details.NetworkMessageTab,
          },
          {
            name: t("monthly_statement"),
            value: `${translatePrefix}.monthly_statement` as Iprefix,
            permissionCode: PermissionKey.Details.MonthlyStatementTab.prefix,
          },
        ]}
        forceTranslatePrefix={translatePrefix}
      />
    ),
    onConfirm: () => { },
    isConfirmHidden: true,
    onCancel: () => { },
    isCancelHidden: true,

    actionButtons: <CloseBtnEle />,

    isLoadingDialog: true,
    size: "xl",
    dialogHeight: {
      height: "92vh",
      maxHeight: "796px",
    },
  };

  return <DialogInOne {...dialogConfig} />;
}
