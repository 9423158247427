import customerSpendingReport from './customerSpendingReport';
import dailyTransaction from './dailyTransaction';
import transactionPostMonitoringReport from './transactionPostMonitoringReport';
import suspiciousTransactionReport from './suspiciousTransactionReport';

export default {
  dailyTransaction,
  customerSpendingReport,
  transactionPostMonitoringReport,
  suspiciousTransactionReport,
};
