import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Container } from "../../../components/MuiGenerals";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowsProp,
  } from "@mui/x-data-grid";
import {
    useAlerting,
    useGetMerchant,
    useListMappingTransform,
    usePermission,
    useTranslation,
  } from "../../../hooks";

import { customSx } from "../../../utils/styling";
import {
    apiObj as api,
    omitKeyObj,
    PermissionKey,
    TableApiRes,
    translateKeyObj as TK,
    translatePrefix,
    useZusParams,
  } from "./config";
import {
    displayAmountCurrying,
    downloadFiles,
    getFullApiResponse,
    toDisplayTime,
} from "../../../utils/helper";
import {
    CustomPagination,
    NoRowsOverlay,
    TableTab,
  } from "../../../components/Layout";
import { getTransactionSubTypeTranslateKey } from "../../../features/common/helpers/getTransactionSubTypeDisplayValue";
import { ExportBtn } from "../../../components/Button";
import { useGenGridCol } from "../../../utils/ComponentHelper";
import { LoadingDialog } from "../../../components";
import { dataGridDefaults } from "../../../utils/constant";
import { EnumE6TransactionType } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Statement";
  
function ViewTable() {
    const { alerting } = useAlerting();
    const { tc } = useTranslation(translatePrefix);
    const zusParams = useZusParams();
    const [count, setCount] = useState(0);
    const listMapping = useListMapping("export");

    const { hasPermission } = usePermission();

    const onExport = async () => {
        if (count === 0) {
          return alerting("error", tc("no_data_export"));
        }
        const apiFn = (page: number, pageSize: number, signal: any) =>
          api.export({ ...zusParams.body, page, pageSize }, { signal });
        const rawRes = await getFullApiResponse(apiFn, count, true);
        if (rawRes.length === 0) return;
        const omitKeys = omitKeyObj.export;
    
        const res = listMapping(rawRes, omitKeys);
        const config = {};
        downloadFiles(`Suspicious Transaction Report`, res, config);
      };
    return (
        <Container
          style={customSx.datagridContainer}
          maxWidth={false}
          disableGutters
        >
          <ExportBtn
            isShown={hasPermission(PermissionKey.Export)}
            onExport={onExport}
          />
          <TableTab>
            <TableList setCount={setCount} />
          </TableTab>
        </Container>
      );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }){
    const { setCount } = props;

    const zusParams = useZusParams();


    const listMapping = useListMapping("table");


    const listRes = useQuery({
        queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
        queryFn: () => api.table(zusParams.body),
    });

    const { rows = [], count = 0 } =
        (listRes.data as any as { rows: any[]; count: number }) || {};
    
    const omitKeys = omitKeyObj.table;
    const content: GridRowsProp = listMapping(rows, omitKeys);

    useEffect(() => setCount(count), [listRes]);

    const columns: GridColDef[] = [
        useGenGridCol(TK.ruleName, {
            minWidth: 200,
        }),
        useGenGridCol(TK.ruleVersion, {}),
        useGenGridCol(TK.outputDescription, {
            minWidth: 150,
        }),
        useGenGridCol(TK.outputValue, {}),
        useGenGridCol(TK.transactionId, {
            minWidth: 150,
        }),
        useGenGridCol(TK.transactionTime, {
            minWidth: 150,
        }),
        useGenGridCol(TK.settlementTime, {
            minWidth: 150,
        }),
        useGenGridCol(TK.programName, {
            minWidth: 200,
        }),
        useGenGridCol(TK.customerNumber, {
            minWidth: 150,
        }),
        useGenGridCol(TK.cardNumber, {
            minWidth: 150,
        }),
        useGenGridCol(TK.panLast4, {
            minWidth: 150,
        }),
        useGenGridCol(TK.transactionType, {
            minWidth: 150,
        }),
        useGenGridCol(TK.subType, {
            minWidth: 150,
        }),
        useGenGridCol(TK.paymentMethod, {
            minWidth: 150,
        }),
        useGenGridCol(TK.acquirerCurrency, {
            minWidth: 150,
        }),
        useGenGridCol(TK.acquirerAmount, {
            minWidth: 150,
        }),
        useGenGridCol(TK.authorizationCode, {
            minWidth: 150,
        }),
        useGenGridCol(TK.onlineTransaction, {
            minWidth: 150,
        }),
        useGenGridCol(TK.merchantCategoryCodes, {
            minWidth: 150,
        }),
        useGenGridCol(TK.cardAcceptorName, {
            minWidth: 150,
        }),
        useGenGridCol(TK.cardAcceptorCountryCode, {
            minWidth: 150,
        }),
        useGenGridCol(TK.cardAcceptorCity, {
            minWidth: 150,
        }),
        useGenGridCol(TK.memo, {
            minWidth: 150,
        }),
        useGenGridCol(TK.createdBy, {
            minWidth: 200,
        }),
        useGenGridCol(TK.creationTime, {
            minWidth: 150,
        }),
    ];
    if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
    return (
        <>
            <DataGrid
                {...dataGridDefaults}
                rows={content} 
                rowCount={count}
                columns={columns}
                page={zusParams.body.page}
                onPageChange={zusParams.setPage}
                components={{
                    NoRowsOverlay,
                    Footer: CustomPagination,
                }}
                componentsProps={{
                    footer: { totalRecords: count },
                }}
            />
        </>
    );
}

const useListMapping = (mode: "export" | "table") => {
    const { t, tb } = useTranslation("enumConstants");
    const listMappingTransform = useListMappingTransform(mode);
    const merchantObj = useGetMerchant();
    
    const listMapping = (array: any[], omitKeys: string[] = []) => {
        const res = array.map((item: TableApiRes) => {
      
            const toDisplayAcquirerAmount = displayAmountCurrying(0, 2);

            const transactionSubTypeTranslateKey = getTransactionSubTypeTranslateKey(
                item.transactionSubType
            );

            const mappedResult = [
                ["rawData", item],
                [TK.ruleName, item.ruleName],
                [TK.ruleVersion, item.ruleVersion],
                [TK.outputDescription, item.outputDesc],
                [TK.outputValue, item.outputValue],
                [TK.transactionId, item.transactionId],
                [TK.transactionTime, toDisplayTime(item.creationTime)],
                [TK.settlementTime, toDisplayTime(item.settlementDate)],
                [TK.programName, item.programName],
                [TK.customerNumber, item.customerNumber],
                [TK.cardNumber, item.cardNumber],
                [TK.panLast4, item.panLast4],
                [TK.transactionType, t(EnumE6TransactionType[item.transactionType])],
                [TK.subType, t(transactionSubTypeTranslateKey)],
                [TK.paymentMethod, item.paymentMethod],
                [TK.acquirerCurrency, item.acquirerCurrency],
                [TK.acquirerAmount, toDisplayAcquirerAmount(item.acquirerAmount)],
                [TK.authorizationCode, item.authCode],
                [TK.onlineTransaction, tb(item.isOnline)],
                [TK.merchantCategoryCodes, item.mcc],
                [TK.cardAcceptorName, item.cardAcceptorName],
                [TK.cardAcceptorCountryCode, item.cardAcceptorCountryCode],
                [TK.cardAcceptorCity, item.cardAcceptorCity],
                [TK.memo, item.memo],
                [TK.createdBy, item.createdBy],
                [TK.creationTime, toDisplayTime(item.createdDate)],
            ].filter(([key]) => !omitKeys.includes(key as string))
            return mappedResult;
        });

        const output = res.map(listMappingTransform)
        return output;
    };
    return listMapping;
}
export default ViewTable;