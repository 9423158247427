import { ChangeEvent, useState } from "react";

import {
  EnumBalanceAdjustmentStatus,
  EnumBalanceAdjustmentType,
} from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";

import {
  MpTextField,
  MpTextFieldMultiple,
  MultipleSelection,
  SingleSelection,
} from "../../../components";
import { useDatePicker } from "../../../components/DatePicker";
import {
  Button,
  Container,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import CollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters";
import ToggleFilterButton from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton";
import useCollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters";
import FilterSectionActionRow from "../../../features/common/filterTable/layout/FilterSectionActionRow";
import { Filter } from "../../../features/common/filterTable/types";
import { useGetMerchant, usePermission, useTranslation } from "../../../hooks";
import { strToStrArrForFilter } from "../../../utils/helper";
import { useZusDialogStore } from "../../../zustand/store";
import CreateRequestDialog from "./components/CreateRequestDialog";
import {
  Ifield,
  initFields,
  initZusParams,
  IsearchParam,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import AdjustmentSubTypeSingleSelection from "../../../features/common/filterTable/components/Filters/AdjustmentSubTypeSingleSelection";

function ViewFilter() {
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifield>(initFields);

  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();

  const merchantObj = useGetMerchant();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const zusParams = useZusParams();

  const getParams: () => IsearchParam = () => {
    const {
      customerNumber,
      adjustmentType: adjustType,
      subType: transactionSubType,
      ...rest
    } = fields;
    const customerNumbers = strToStrArrForFilter(customerNumber);

    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } =
      DateObj.LastModifiedTime;

    return {
      ...initZusParams,
      ...rest,
      customerNumbers,
      adjustType,
      transactionSubType,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.lastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.merchantName,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.merchantName) })}
          value={fields.merchantId}
          onChange={onChange("merchantId")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, merchantId: "" }))
          }
          nameFn={(name) => name}
          isNoSorting
          enumData={merchantObj}
        />
      ),
    },
    {
      labelKey: TK.orderId,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.orderId) })}
          value={fields.orderId}
          onChange={onChange("orderId")}
        />
      ),
    },
    {
      labelKey: TK.customerNumber,
      filter: (
        <MpTextFieldMultiple
          label={tc("phInputField", { fieldName: t(TK.customerNumber) })}
          value={fields.customerNumber}
          onChange={onChange("customerNumber")}
          count={apiParams.customerNumbers.length}
        />
      ),
    },
    {
      labelKey: TK.status,
      filter: (
        <MultipleSelection
          label={tc("phSelection", { fieldName: t(TK.status) })}
          value={fields.statuses}
          onChange={(statuses) =>
            setFields((fields) => ({ ...fields, statuses }))
          }
          enumData={EnumBalanceAdjustmentStatus}
        />
      ),
    },
    {
      labelKey: TK.adjustmentType,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.adjustmentType) })}
          value={fields.adjustmentType}
          onChange={onChange("adjustmentType")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, adjustmentType: "" }))
          }
          enumData={EnumBalanceAdjustmentType}
        />
      ),
    },
    {
      labelKey: TK.subType,
      filter: (
        <AdjustmentSubTypeSingleSelection
          value={fields.subType}
          onChange={onChange("subType")}
          onClear={() => setFields((fields) => ({ ...fields, subType: "" }))}
          adjustmentType={fields.adjustmentType}
        />
      ),
    },
  ];

  const openCreateRequestDialog = () => zusDialog.open("createRequestDialog");

  return (
    <Container disableGutters maxWidth={false}>
      <CreateRequestDialog />

      <FilterSectionActionRow>
        {hasPermission(PermissionKey.CreateRequest) && (
          <Button variant="outlined" onClick={openCreateRequestDialog}>
            {t("create_request")}
          </Button>
        )}
        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>

      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onReset={onReset}
        onSearch={onSearch}
      />
    </Container>
  );
}

export default ViewFilter;
