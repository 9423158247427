import React from "react";
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from "../../../../../components/Layout/DialogContentLayout";
import { useTranslation } from "../../../../../hooks";

interface IProps {
  e6ProgramName: string;
  cardProfileName: string;
  pansIssued: string;
  description: string;
  cardType: string;
  iin: string;
  serviceCode: string;
  iinSegment: string;
  expiryNoOfMonths: string;
  embossedName: string;
  pinLength: string;
  panLength: string;
  issuerIdentifier: string;
  productCode: string;
}

const Content = (props: IProps) => {
  const {
    e6ProgramName,
    cardProfileName,
    pansIssued,
    description,
    cardType,
    iin,
    serviceCode,
    iinSegment,
    expiryNoOfMonths,
    embossedName,
    pinLength,
    panLength,
    issuerIdentifier,
    productCode,
  } = props;

  const { t } = useTranslation("cardProfileSetting");

  return (
    <div>
      <Container>
        <Row>
          <RowHeader>{t("e6ProgramName")}</RowHeader>
          <RowContent>{e6ProgramName}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("cardProfileName")}</RowHeader>
          <RowContent>{cardProfileName}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("pansIssued")}</RowHeader>
          <RowContent>{pansIssued}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("description")}</RowHeader>
          <RowContent>{description}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("cardType")}</RowHeader>
          <RowContent>{cardType}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("iin")}</RowHeader>
          <RowContent>{iin}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("serviceCode")}</RowHeader>
          <RowContent>{serviceCode}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("iinSegment")}</RowHeader>
          <RowContent>{iinSegment}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("expiryNoOfMonths")}</RowHeader>
          <RowContent>{expiryNoOfMonths}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("embossedName")}</RowHeader>
          <RowContent>{embossedName}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("pinLength")}</RowHeader>
          <RowContent>{pinLength}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("panLength")}</RowHeader>
          <RowContent>{panLength}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("issuerIdentifier")}</RowHeader>
          <RowContent>{issuerIdentifier}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t("productCode")}</RowHeader>
          <RowContent>{productCode}</RowContent>
        </Row>
      </Container>
    </div>
  );
};

export default Content;
