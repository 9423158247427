import { format, parse } from "date-fns";

import { useListMappingTransform } from "../../../../../hooks";
import { toDisplayTime } from "../../../../../utils/helper";
import { translateKeyObj as TK } from "../../config";

export const useListMapping = () => {
  const listMappingTransform = useListMappingTransform("table");

  const formatStatementDate = (dateStr: string): string => {
    const parsedDate = parse(dateStr, "yyyyMMdd", new Date());

    return format(parsedDate, "yyyy-MM-dd");
  };

  const formatMonth = (dateStr: string): string => {
    const parsedDate = parse(dateStr, "yyyyMM", new Date());

    return format(parsedDate, "yyyy/MM");
  };

  const listMapping = (array: any[]) => {
    const res = array.map((item: any) => {
      const mappedResult = [
        ["rawData", item],
        [TK.programName, item.programName],
        [TK.month, formatMonth(item.month)],
        [TK.statementDate, formatStatementDate(item.statementDate)],
        [TK.reportName, item.reportName],
        [TK.creationTime, toDisplayTime(item.createdAt)],
        [TK.lastModifiedTime, toDisplayTime(item.updatedAt)],
      ];
      return mappedResult;
    });
    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};
