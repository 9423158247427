import React, { useEffect, useState } from 'react';
import { DialogInOne } from "../../../../../components"
import { useAlerting, useGetMerchant, usePermission, useTranslation } from '../../../../../hooks';
import { PermissionKey, translatePrefix } from '../../config';
import api from '../../../../../api';
import Content from './Content';
import { updateCardProfileStatusFields, ICardData } from './types';


interface ReissueCardDialogProps {
    title: string;
    isOpen: boolean;
    closeDialog: () => void;
    refreshTable: () => void;
    cardData: ICardData;
}

interface IEditCardProfileInput {
    merchantId: number;
    programName: string;
    cardProfileName: string;
    status: number;
}

const initFields = {
    merchantId: 99,
    merchantName: "",
    programName: "",
    cardProfileName: "",
    status: 0,
}

const EditCardProfileNameDialog: React.FC<ReissueCardDialogProps> = ({
    title,
    isOpen,
    closeDialog,
    refreshTable,
    cardData,
}) => {
    const merchantMap = useGetMerchant();
    const { hasPermission } = usePermission();
    const { tc, translate, t } = useTranslation(translatePrefix);
    const { alerting } = useAlerting();
    const [fields, setFields] = useState<updateCardProfileStatusFields>(initFields);

    const hasPermissionToEdit = hasPermission(PermissionKey.Edit);

    const updateField = (fieldName: string, value: string | boolean | number) => {
        setFields((f: any) => ({ ...f, [fieldName]: value }));
    };

   
    useEffect(() => {
        if(cardData.rawData!==undefined){
            updateField('merchantId', cardData.rawData.merchantId)
            updateField('merchantName', cardData.merchant_name)
            updateField('programName', cardData.program_name)
            updateField('cardProfileName', cardData.card_profile_name)
            updateField('status', cardData.rawData.status)
        }
    }, [cardData])

    const checkIsEdited = () => {
        return !!Object.entries(fields).filter(([key, val]) => {
          const typedKey = key as keyof typeof fields;
    
          if (typedKey === 'status') {
            return String(val) !== String(cardData.rawData.status);
          }
        }).length;
      };

    const onSubmit = async () => {
        const isEdited = checkIsEdited();

        if (!isEdited) {
            return alerting('warning', tc('no_changes_made_warning'));
        }

        if(!fields.cardProfileName) {
            return alerting("warning", translate("validation.inputRequired", {
                fieldName: t("card_profile_name"),
            }));
        }
        const data: IEditCardProfileInput = {
            merchantId: fields.merchantId,
            programName: fields.programName,
            cardProfileName: fields.cardProfileName,
            status: fields.status
        }
        const res = await api.Setting.assignCardProfile.postEdit(data);

        if (!res) {
            return;
        }

        alerting("success", t("edit_success"))
        refreshTable();
        closeDialog();
    };

   

    const dialogConfig = {
        title,
        self: {
            open: isOpen,
            onClose: () => {
                closeDialog();
            },
        },
        content: 
            <Content fields={fields} setField={setFields} />,
        onConfirm: async() => {
            await onSubmit()
        },
        onCancel: () => {
            closeDialog();
        },
        isLoadingDialog: true,
        isConfirmHidden: false,
        size: "md" as any,
    }

    return <DialogInOne {...dialogConfig} />
}

export default EditCardProfileNameDialog;