import { EnumE6CardType } from "@wallet-manager/pfh-node-def-types/dist/src/E6Enum";
import api from "../../../api";
import { Ipage } from "../../../api/types";
import { Iprefix } from "../../../hooks/useTranslation";
import { initZusParams } from "../../../utils/config";
import { createZusInstance } from "../../../zustand/store";

const translatePrefix: Iprefix = "cardProfileSetting";

interface Ifield {
  e6ProgramName: string[];
  cardProfileName: string;
  iin: string;
}

const initFields: Ifield = {
  e6ProgramName: [],
  cardProfileName: "",
  iin: "",
};

interface IsearchParam {
  createdDateFrom: string;
  createdDateTo: string;
  e6ProgramName: string[];
  cardProfileName: string;
  iin: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
} = Object.freeze({
  export: api.CreditManagement.balanceAdjustmentRequest.getExport,
  table: api.CreditManagement.balanceAdjustmentRequest.getAll,
});

const translateKeyObj = Object.freeze({
  operation: "operation",
  creationTime: "creationTime",
  lastModifiedTime: "lastModifiedTime",
  e6ProgramName: "e6ProgramName",
  cardProfileName: "cardProfileName",
  description: "description",
  cardType: "cardType",
  iin: "iin",
  iinSegment: "iinSegment",
  createdBy: "createdBy",
  lastModifiedBy: "lastModifiedBy",
});

const omitKeyObj = Object.freeze({
  export: ["rawData"],
  table: [],
});

type TableApiResType = {
  id: number;
  e6ProgramName: string;
  cardProfileName: string;
  description: string;
  cardType: EnumE6CardType;
  iin: string;
  iinSegment: string[];
  createdBy: string;
  modifiedBy: string;
  createdDate: string;
  lastModifiedDate: string;
};

export {
  translatePrefix,
  type Ifield,
  type IsearchParam,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
  type TableApiResType,
};
