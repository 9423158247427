import { SingleSelection } from "../../../../../components";
import { useGetMerchant, useTranslation } from "../../../../../hooks";


interface IProps {
    label?: string;
    value: any;
    onChange: (e: any) => void;
    onClear: () => void;
}

const MerchantNameSingleSelection = (props: IProps) => {
    const { label, value, onChange, onClear } = props;

    const MerchantEnum = useGetMerchant();

    const { tc } = useTranslation("common");

    let selectValue = value;

    return (
        <SingleSelection
            label={tc("phSelection", { fieldName: tc("merchantName") })}
            value={selectValue}
            onChange={onChange}
            clearSelect={onClear}
            enumData={MerchantEnum}
            nameFn={(item)=> item}
            sx={{ formControl: { width: "70%" } }}
        />
    );
};

export default MerchantNameSingleSelection;