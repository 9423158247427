export function convertDateStringToYearMonthDayString(
  date: string | undefined
): string {
  if (!date) {
    return "";
  }
  let dateObject = new Date(date);
  return dateObject.toISOString().split("T")[0];
}

export const formatDateStringFromHyphenToSlash = (month: string) => {
  return month.replace("-", "/");
};

export const formatDateStringFromSlashToHyphen = (month: string) => {
  return month.replace("/", "-");
};
