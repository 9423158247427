import { GridColDef } from "@mui/x-data-grid";
import { useGenGridCol } from "../../../../../utils/ComponentHelper";

import { translateKeyObj as TK } from "../../config";
import { usePermission } from "../../../../../hooks";
import { FeatureCreditManagement } from "@wallet-manager/pfh-node-def-types/dist/src/FeatureCode";

export const useColumn = (renderDownloadCell: any) => {
  const { hasPermission } = usePermission();
  const isHideOperationColumn = !hasPermission(
    FeatureCreditManagement.CustomerAccount.Details.MonthlyStatementTab.Download
  );

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, {
      renderCell: renderDownloadCell,
      hide: isHideOperationColumn,
    }),
    useGenGridCol(TK.programName, { minWidth: 150 }),
    useGenGridCol(TK.month),
    useGenGridCol(TK.statementDate, { minWidth: 150 }),
    useGenGridCol(TK.reportName, { minWidth: 150 }),
    useGenGridCol(TK.creationTime),
    useGenGridCol(TK.lastModifiedTime, { minWidth: 150 }),
  ];
  return {
    columns,
  };
};
