import { useState } from "react";
import { useQuery } from "react-query";

import api from "../../../../../api";
import { LoadingDialog, SingleSelection } from "../../../../../components";
import { SelectChangeEvent } from "../../../../../components/MuiGenerals";
import { useTranslation } from "../../../../../hooks";

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const IinSingleSelection = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { label, value, onChange, onClear } = props;

  const [iinList, setIinList] = useState<string[]>();

  const { tc } = useTranslation("common");

  const getIinEnum = () => {
    if (!iinList || iinList.length === 0) return {};

    return iinList.reduce((acc: Record<string, string>, curr: string) => {
      return {
        ...acc,
        [curr]: curr,
      };
    }, {});
  };

  const handleQuery = async () => {
    setIsLoading(true);
    const res = await api.Setting.cardProfileSetting.getIinList();
    setIsLoading(false);

    return res;
  };

  const onQuerySuccess = (res: any) => {
    setIinList(res?.result);
  };

  const IinEnum = getIinEnum();

  useQuery("iinList", {
    queryFn: handleQuery,
    onSuccess: onQuerySuccess,
  });

  return (
    <>
      <LoadingDialog forceOpen={isLoading} />
      <SingleSelection
        label={label || tc("phSelection", { fieldName: tc("iin") })}
        value={value || ""}
        clearSelect={onClear}
        onChange={onChange}
        enumData={IinEnum}
        nameFn={(item) => item}
      />
    </>
  );
};

export default IinSingleSelection;
