import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import { EnumCardStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import { EnumE6CardState } from "@wallet-manager/pfh-node-def-types/dist/src/E6Enum";

import { LoadingDialog } from "../../../components";
import { ButtonMenu, ExportBtn } from "../../../components/Button";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab,
} from "../../../components/Layout";
import { Container } from "../../../components/MuiGenerals";
import {
  useAlerting,
  useGetMerchant,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../hooks";
import { useGenGridCol } from "../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../utils/constant";
import {
  convertTrueFalseToYesNo,
  downloadFiles,
  getFullApiResponse,
  toDisplayTime,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import ReissueCardDialog from "./components/ReissueCardDialog";
import {
  apiObj as api,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import { Card } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import TerminateCardDialog from "./components/TerminateCardDialog";
import DetailsDialog from "./components/DetailsDialog";
import TokenListDialog from "./components/TokenListDialog";
import { FeatureCode } from "@wallet-manager/pfh-node-def-types";

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);

  const listMapping = useListMapping("export");
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(`Card_list`, res, config);
  };

  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const { hasPermission } = usePermission();
  const displayOperationField =
    hasPermission(PermissionKey.Reissue) ||
    hasPermission(PermissionKey.Terminate) ||
    hasPermission(PermissionKey.Details) ||
    hasPermission(PermissionKey.TokenList.prefix);

  const listMapping = useListMapping("table");
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => api.table(zusParams.body),
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: Card[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;
  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes]);

  const [isReissueCardDialogOpen, setIsReissueCardDialogOpen] = useState(false);
  const [isTerminateCardDialogOpen, setIsTerminateCardDialogOpen] =
    useState(false);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [isTokenListDialogOpen, setIsTokenListDialogOpen] = useState(false);

  const { t } = useTranslation(translatePrefix);

  const generateOperationMenuOptions = (
    params: GridRenderCellParams<any, any, any>,
    setSelectedRow: any
  ) => {
    const rawData = params.row || {};
    const isNotAssignedAndNotActivated =
      rawData.rawStatus !== EnumCardStatus.Assigned &&
      rawData.rawStatus !== EnumCardStatus.Activated;

    const isTerminated = rawData.rawStatus === EnumCardStatus.Terminated;

    const getIsNoShowTerminateButton = () => {
      // Only check e6 card state for "Terminate Card", not "Termination Details"
      if (
        !isTerminated &&
        rawData.e6_card_state === EnumE6CardState[EnumE6CardState.Invalid]
      ) {
        return true;
      }

      if (!isTerminated && !hasPermission(PermissionKey.Terminate)) {
        return true;
      }

      if (isTerminated && !hasPermission(PermissionKey.Details)) {
        return true;
      }

      return false;
    };

    return [
      {
        name: t(TK.reissueNewCard),
        onClickFn: () => {
          setSelectedRow(params.row);
          setIsReissueCardDialogOpen(true);
        },
        noShow: !hasPermission(PermissionKey.Reissue),
        isDisabled:
          isNotAssignedAndNotActivated || !hasPermission(PermissionKey.Reissue),
      },
      {
        name: isTerminated ? t(TK.TerminationDetails) : t(TK.terminateCard),
        onClickFn: () => {
          setSelectedRow(params.row);
          if (isTerminated) {
            setIsDetailsDialogOpen(true);
          } else {
            setIsTerminateCardDialogOpen(true);
          }
        },
        noShow: getIsNoShowTerminateButton(),
        isDisabled: false,
      },
      {
        name: t(TK.tokenList),
        onClickFn: () => {
          setSelectedRow(params.row);
          setIsTokenListDialogOpen(true);
        },
        noShow: !hasPermission(PermissionKey.TokenList.prefix),
        isDisabled: false,
      },
    ];
  };

  const refreshTable = () => {
    zusParams.refetch();
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, {
      minWidth: 150,
      hide: !displayOperationField,
      renderCell: (params) => {
        return (
          <>
            <ButtonMenu
              title={t(TK.operation)}
              options={generateOperationMenuOptions(params, setSelectedRow)}
            />
          </>
        );
      },
    }),
    useGenGridCol(TK.merchantName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.orderId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.orderSequence, {
      minWidth: 150,
    }),
    useGenGridCol(TK.partnerName),
    useGenGridCol(TK.programName),
    useGenGridCol(TK.cardProfileName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.status, { minWidth: 150 }),
    useGenGridCol(TK.customerNo),
    useGenGridCol(TK.embossedName),
    useGenGridCol(TK.cardNo),
    useGenGridCol(TK.pan),
    useGenGridCol(TK.cardType),
    useGenGridCol(TK.cardCreationTime),
    useGenGridCol(TK.cardExpiry),
    useGenGridCol(TK.e6CardState),
    useGenGridCol(TK.stateUpdatedTime, { minWidth: 150 }),
    useGenGridCol(TK.preCreatedCard),
    useGenGridCol(TK.imported),
    useGenGridCol(TK.blocked),
    useGenGridCol(TK.reissue),
    useGenGridCol(TK.reissueOrderId, { minWidth: 150 }),
    useGenGridCol(TK.originalCardNo, { minWidth: 150 }),
    useGenGridCol(TK.deactivatedOriginalCardOnActivation, { minWidth: 150 }),
    useGenGridCol(TK.cardActivationTime, { minWidth: 150 }),
    useGenGridCol(TK.createdBy, { minWidth: 150 }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedBy, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;

  return (
    <>
      <TokenListDialog
        title={t(TK.tokenList)}
        isOpen={isTokenListDialogOpen}
        closeDialog={() => setIsTokenListDialogOpen(false)}
        rowData={selectedRow}
        refreshTable={refreshTable}
      />
      <ReissueCardDialog
        title={t(TK.confirmReissue)}
        isOpen={isReissueCardDialogOpen}
        closeDialog={() => {
          setIsReissueCardDialogOpen(false);
        }}
        cardData={selectedRow}
        refreshTable={refreshTable}
      />
      <TerminateCardDialog
        title={t(TK.confirmTerminate)}
        isOpen={isTerminateCardDialogOpen}
        closeDialog={() => setIsTerminateCardDialogOpen(false)}
        cardData={selectedRow}
        refreshTable={refreshTable}
      />
      <DetailsDialog
        title={t(TK.TerminationDetails)}
        isOpen={isDetailsDialogOpen}
        closeDialog={() => setIsDetailsDialogOpen(false)}
        cardData={selectedRow}
      />
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const { t } = useTranslation("enumConstants");
  const listMappingTransform = useListMappingTransform(mode);
  const merchantObj = useGetMerchant();

  const listMapping = (array: Card[], omitKeys: string[] = []) => {
    const res = array.map((item: Card) => {
      const cardExpiryDisplayValue =
        item.expiry.slice(0, 4) + "/" + item.expiry.slice(-2);
      const mappedResult = [
        [TK.merchantName, merchantObj[item.merchantId]],
        [TK.orderId, item.orderId],
        [TK.orderSequence, item.orderSeq],
        [TK.partnerName, item.partnerName],
        [TK.programName, item.programName],
        [TK.cardProfileName, item.cardProfileName],
        [TK.status, t(EnumCardStatus[item.status])],
        [TK.customerNo, item.customerNumber],
        [TK.embossedName, item.embossedName],
        [TK.cardNo, item.cardNumber],
        [TK.pan, item.panLast4],
        [TK.cardType, item.type],
        [TK.cardCreationTime, toDisplayTime(item.cardCreatedTime)],
        [TK.cardExpiry, cardExpiryDisplayValue],
        [TK.e6CardState, t(EnumE6CardState[item.state])],
        [TK.stateUpdatedTime, toDisplayTime(item.stateUpdatedTime)],
        [TK.preCreatedCard, t(convertTrueFalseToYesNo(item.preCreatedCard))],
        [TK.imported, t(convertTrueFalseToYesNo(item.isImported))],
        [TK.blocked, t(convertTrueFalseToYesNo(item.blocked))],
        [TK.reissue, t(convertTrueFalseToYesNo(item.reissue))],
        [TK.reissueOrderId, item.reissueOrderId],
        [TK.originalCardNo, item.oldCardNumber],
        [
          TK.deactivatedOriginalCardOnActivation,
          t(convertTrueFalseToYesNo(item.deactivateOnNewCardActivation)),
        ],
        [TK.cardActivationTime, toDisplayTime(item.cardActivatedDate)],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedBy, item.modifiedBy],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
        // Not Show in Table, For Checking the Reissue Button show or not
        ["rawStatus", item.status],
        ["cardId", item.id],
        ["rawData", item],
      ].filter(([key]) => !omitKeys.includes(key as string));
      return mappedResult;
    });
    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
