import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid";
import { LoadingDialog } from "../../../components";
import { ButtonMenu, ExportBtn } from "../../../components/Button";
import {
    CustomPagination,
    NoRowsOverlay,
    TableTab,
} from "../../../components/Layout";
import { Container } from "../../../components/MuiGenerals";
import {
    useAlerting,
    useGetMerchant,
    useListMappingTransform,
    usePermission,
    useTranslation,
} from "../../../hooks";
import { useGenGridCol } from "../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../utils/constant";
import {
    convertTrueFalseToYesNo,
    downloadFiles,
    getFullApiResponse,
    toDisplayTime,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import {
    apiObj as api,
    omitKeyObj,
    PermissionKey,
    translateKeyObj as TK,
    translatePrefix,
    useZusParams,
} from "./config";
import { useAppSelector } from "../../../reducer/hooks";
import { selectProfile } from "../../../reducer/profileSlice";
import OperationsCell from "./components/EditCardProfileButton/index";
import { EnumCardStatus, EnumMerchantCardProfileStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import EditCardProfileNameDialog from "./components/EditCardProfileNameDialog";


function ViewTable() {
    const { alerting } = useAlerting();
    const { tc } = useTranslation(translatePrefix);
    const zusParams = useZusParams();
    const [count, setCount] = useState(0);
    const listMapping = useListMapping("export");

    const { hasPermission } = usePermission();

    const onExport = async () => {
        if (count === 0) {
            return alerting("error", tc("no_data_export"));
        }
        const apiFn = (page: number, pageSize: number, signal: any) =>
            api.export({ ...zusParams.body, page, pageSize }, { signal });
        const rawRes = await getFullApiResponse(apiFn, count, true);
        if (rawRes.length === 0) return;
        const omitKeys = omitKeyObj.export;
    
        const res = listMapping(rawRes, omitKeys);
        const config = {};
        downloadFiles(`Assign Card Profile`, res, config);
    };
    return (
        <Container
            style={customSx.datagridContainer}
            maxWidth={false}
            disableGutters
        >
            <ExportBtn
                isShown={hasPermission(PermissionKey.Export)}
                onExport={onExport}
            />
            <TableTab>
                <TableList setCount={setCount}/>
            </TableTab>
        </Container>
    );

}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }){
    const { setCount } = props;
    const { alerting } = useAlerting();
    const zusParams = useZusParams();
    const { hasPermission } = usePermission();
    const { t } = useTranslation(translatePrefix);

    const [isEditStatusDialogOpen, setIsEditStatusDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const profile = useAppSelector(selectProfile);

    const isHideOperationColumn = !(
        hasPermission(PermissionKey.Edit)
    );

    const listRes = useQuery({
        queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
        queryFn: () => api.table(zusParams.body),
    });

    const refreshTable = () => {
        zusParams.refetch();
    };

    const { rows = [], count = 0 } =
        (listRes.data as any as { rows: any[]; count: number }) || {};
        
    const omitKeys = omitKeyObj.table;
    const listMapping = useListMapping("table");
    const content: GridRowsProp = listMapping(rows, omitKeys);

    const handleEditStatusButtonClick = (params: GridRenderCellParams) => {
        setSelectedRow(params.row);
        setIsEditStatusDialogOpen(true);
    };

    const closeEditStatusDialog = () => {
        setIsEditStatusDialogOpen(false);
    };

    useEffect(() => setCount(count), [listRes]);

    const renderOperationCell = (params: any) => {
        return (
          <ButtonMenu
            title={t("operations")}
            options={[
              {
                name: t("edit"),
                onClickFn: () => {
                    handleEditStatusButtonClick(params);
                },
                isDisabled: false,
                noShow: !hasPermission(PermissionKey.Edit),
              },
            ]}
          />
        );
      };

    const columns: GridColDef[] = [
        useGenGridCol(TK.operation, {
            renderCell: renderOperationCell,
        }),
        useGenGridCol(TK.merchantName, { minWidth : 200 }),
        useGenGridCol(TK.programName, { minWidth : 200 }),
        useGenGridCol(TK.cardProfileName, { minWidth : 200 }),
        useGenGridCol(TK.status),
        useGenGridCol(TK.createdBy, { minWidth: 150 }),
        useGenGridCol(TK.creationTime, { minWidth: 150 }),
        useGenGridCol(TK.lastModifiedBy, { minWidth: 150 }),
        useGenGridCol(TK.lastModifiedTime, { minWidth: 150 }),
        
    ].filter((col) => {
        if (isHideOperationColumn && col.field === TK.operation) return false;

        return true;
    });

    if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;

    return (
        <>
            <EditCardProfileNameDialog 
                title={t("edit_card_profile_name_title")}
                isOpen={isEditStatusDialogOpen}
                closeDialog={closeEditStatusDialog} 
                refreshTable={refreshTable} 
                cardData={selectedRow}            />

            <DataGrid
                {...dataGridDefaults}
                rows={content}
                rowCount={count}
                columns={columns}
                page={zusParams.body.page}
                onPageChange={zusParams.setPage}
                components={{
                    NoRowsOverlay,
                    Footer: CustomPagination,
                }}
                componentsProps={{
                    footer: { totalRecords: count },
                }}
            />
        </>
    )

    
}

const useListMapping = (mode: "export" | "table") => {
    const { t } = useTranslation("assignCardProfile");
    const listMappingTransform = useListMappingTransform(mode);
    const merchantObj = useGetMerchant();
    
    const listMapping = (array: any[], omitKeys: string[] = []) => {
        const res = array.map((item: any) => {
            const mappedResult = [
                [TK.merchantName, merchantObj[item.merchantId]],
                [TK.programName, item.programName],
                [TK.cardProfileName, item.cardProfileName],
                [TK.status, t(EnumMerchantCardProfileStatus[item.status])], //
                [TK.createdBy, item.createdBy],
                [TK.creationTime, toDisplayTime(item.createdDate)],
                [TK.lastModifiedBy, item.modifiedBy],
                [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
                ["rawData", item],

            ].filter(([key]) => !omitKeys.includes(key));
            return mappedResult;
        });
        const output = res.map(listMappingTransform);
        return output;
    }

    return listMapping;
}

export default ViewTable;