import React from "react";
import { Row } from "../../../../components/Layout/DialogContentLayout";

const DialogRow = ({ children }: { children: React.ReactNode }) => {
  return (
    <Row rowStyle={{ maxWidth: "50%", padding: "0 24px" }}>{children}</Row>
  );
};

export default DialogRow;
