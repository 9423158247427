import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Container } from "@mui/system";

import {
  MpTextField,
  MultipleSelection,
  SingleSelection,
} from "../../../components";
import { useDatePicker } from "../../../components/DatePicker";
import { SelectChangeEvent } from "../../../components/MuiGenerals";
import CollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters";
import ToggleFilterButton from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton";
import useCollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters";
import FilterSectionActionRow from "../../../features/common/filterTable/layout/FilterSectionActionRow";
import { Filter } from "../../../features/common/filterTable/types";
import {
  useGetMerchant,
  useGetProgram,
  usePermission,
  useTranslation,
} from "../../../hooks";
import {
  Ifield,
  initFields,
  initZusParams,
  IsearchParam,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import { Create } from "react-admin";
import { file } from "jszip";
import ProgramNameMultipleSelection from "../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection";
import CardProfileNameAutoComplete from "../../../features/Setting/components/CardProfileNameAutoComplete";
import { EnumMerchantCardProfileStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import AssignCardProfileButton from "./components/EditCardProfileButton";
import AssignCardProfileRequestButton from "./components/AssignCardProfileButton";
import AssignCardProfileRequestDialog from "./components/AssignCardProfileDialog";

function ViewFilter() {
  const { t, tc } = useTranslation(translatePrefix);
  const [searchParams] = useSearchParams();
  const permission = usePermission();
  const [isAssignCardProfileRequestOpen, setIsAssignCardProfileRequestOpen] =
    useState<boolean>(false);

  const [fields, setFields] = useState<Ifield>({
    ...initFields,
  });

  const merchantObj = useGetMerchant();
  const { merchantId } = fields;
  const programObj = useGetProgram(); //useGetProgram(merchantId) || {};

  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const hasAssignCardProfilePermission = permission.hasPermission(
    PermissionKey.AssignProfile
  );

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const zusParams = useZusParams();

  const getParams: () => IsearchParam = () => {
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } =
      DateObj.LastModifiedTime;

    return {
      ...initZusParams,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
      programName: fields.programName,
      merchantId: fields.merchantId,
      cardProfileName: fields.cardProfileName,
      status: fields.status,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const openAssignCardProfileRequestDialog = () => {
    setIsAssignCardProfileRequestOpen(true);
  };

  const closeAssignCardProfileRequestDialog = () => {
    setIsAssignCardProfileRequestOpen(false);
  };

  const refreshTable = () => {
    zusParams.refetch();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.lastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.merchantName,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.merchantName) })}
          value={fields.merchantId}
          onChange={(e) => {
            onChange("merchantId")(e);
          }}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, merchantId: "" }))
          }
          nameFn={(name) => name}
          isNoSorting
          enumData={merchantObj}
        />
      ),
    },
    {
      labelKey: TK.programName,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.programName) })}
          value={fields.programName}
          onChange={(e) => {
            onChange("programName")(e);
          }}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, programName: "" }))
          }
          nameFn={(name) => name}
          isNoSorting
          enumData={programObj}
        />
      ),
    },
    {
      labelKey: TK.cardProfileName,
      filter: (
        <CardProfileNameAutoComplete
          label={t("cardProfileName")}
          value={fields.cardProfileName}
          customOnInputChange={(value: any) =>
            setFields({ ...fields, cardProfileName: value })
          }
        />
      ),
    },
    {
      labelKey: TK.status,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.status) })}
          value={fields.status}
          onChange={(e) => {
            onChange("status")(e);
          }}
          clearSelect={() => setFields((fields) => ({ ...fields, status: "" }))}
          nameFn={(name) => t(name)}
          enumData={EnumMerchantCardProfileStatus}
        />
      ),
    },
  ];

  return (
    <>
      <AssignCardProfileRequestDialog
        title={t(TK.assignCardProfile)}
        isOpen={isAssignCardProfileRequestOpen}
        closeDialog={closeAssignCardProfileRequestDialog}
        refreshTable={refreshTable}
      />
      <Container disableGutters maxWidth={false}>
        <FilterSectionActionRow>
          <AssignCardProfileRequestButton
            label={t(TK.assignCardProfile)}
            isShow={hasAssignCardProfilePermission}
            onClick={openAssignCardProfileRequestDialog}
          />
          <ToggleFilterButton
            isCollapse={isCollapse}
            onClick={toggleFilterCollapse}
          />
        </FilterSectionActionRow>

        <CollapsibleFilters
          isCollapse={isCollapse}
          filters={filters}
          onSearch={onSearch}
          onReset={onReset}
        />
      </Container>
    </>
  );
}

export default ViewFilter;
