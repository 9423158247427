import { COLOR } from "../../../../utils/config";
import { roundDownAmount } from "../../helpers/roundDownAmount";
import { ChartBoardCategory, ChartDataType } from "../../../../features/DataAnalysis/components/ChartBoard";
import { useTranslation } from "../../../../hooks";
const TooltipWrapperStyle = {
  backgroundColor: COLOR.Chart.LineChart.TooltipContentBackground,
  padding: "10px",
  borderRadius: "5px",
};

interface ICustomTooltipProps {
  active?: boolean;
  payload?: any;
  label?: string;
  dataType?: ChartDataType;
  chartBoardCategory?:  ChartBoardCategory;
}

const CustomTooltip: React.FC<any> = (props) => {
  const { active, payload, dataType, chartBoardCategory } = props;
  const { tpc, tmccc } = useTranslation("enumConstants");
  if (active && payload && payload.length) {
    const exactDate = payload[0].payload.name;

    return (
      <div style={TooltipWrapperStyle}>
        <p>{exactDate}</p>

        {payload.map((entry: any, index: any) => {
          const getLabel = () => {
            switch (chartBoardCategory) {
              case ChartBoardCategory.MCC:
                  return tmccc(entry.name);
              case ChartBoardCategory.PAYMENT_METHOD_CODE:
                  return tpc(entry.name)
              default:
                  return (
                    entry.payload?.category ||
                    entry.payload?.customer_number ||
                    entry.name
                  );
            }
            
          };

          const label = getLabel();

          const value = roundDownAmount(entry.value, dataType);

          const labelValuePair = `${label}: ${value}`;

          return (
            <div key={`item-${index}`}>
              <p
                style={{
                  color:
                    COLOR.Chart.LineChart.LineColor[
                      index % COLOR.Chart.LineChart.LineColor.length
                    ],
                }}
              >
                {labelValuePair}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
