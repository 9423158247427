import { SingleSelection } from "../../../../../components";
import { SelectChangeEvent } from "../../../../../components/MuiGenerals";
import { useTranslation } from "../../../../../hooks";

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const PanLengthSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc } = useTranslation("common");

  const Enum = {
    16: "16",
    17: "17",
    18: "18",
    19: "19",
  };

  return (
    <SingleSelection
      label={label || tc("phSelection", { fieldName: tc("panLength") })}
      value={value || ""}
      clearSelect={onClear}
      onChange={onChange}
      enumData={Enum}
      nameFn={(item) => item}
    />
  );
};

export default PanLengthSingleSelection;
