import { EPtBalanceAdjustRequest } from "@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal";

import axios from "../axiosInstance";

const { EPtBalanceAdjustRequest: endPoint } = EPtBalanceAdjustRequest;

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.getAll, input);
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.export, input);
}

async function getApprovalDetail(params: any, config?: any) {
  const input = {
    params,
    ...config,
  };
  return await axios.get(endPoint.approvalDetail, input);
}

async function postRetry(params: { requestId: string }, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.retry, input);
}
async function postCreateRequest(
  params: {
    merchantId: number;
    customerNumber: string;
    type: number;
    amount: string;
    currencyCode: string;
    transactionSubType: number;
    relatedTransactionId: string;
  },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.create, input);
}

async function getOffering(
  params: { merchantId: string; customerNumber: string },
  config?: any
) {
  const input = {
    params,
    ...config,
  };
  return await axios.get(endPoint.offering, input);
}

async function approveBalanceAdjustment(params: any, config?: any) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(endPoint.approve, input);
}

async function rejectBalanceAdjustment(params: any, config?: any) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(endPoint.reject, input);
}

export default {
  getAll,
  getExport,
  postRetry,
  postCreateRequest,
  getOffering,
  approveBalanceAdjustment,
  rejectBalanceAdjustment,
  getApprovalDetail,
};
