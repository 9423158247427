import { useState } from "react";
import { useQuery } from "react-query";

import {
  Card,
  TerminateCardRequest,
} from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";

import api from "../../../../../api";
import { DialogInOne, LoadingDialog } from "../../../../../components";
import { useTranslation } from "../../../../../hooks";
import { ICardData } from "../../types/ICard";
import DetailsDialogContent from "../DetailsDialog/DetailDialogContent";
import { IDetailDialogContent } from "./type";

interface DetailsDialogProps {
  title: string;
  isOpen: boolean;
  cardData: ICardData;
  closeDialog: () => void;
}

const DetailsDialog: React.FC<DetailsDialogProps> = ({
  title,
  isOpen,
  cardData,
  closeDialog,
}) => {
  const [data, setData] = useState<IDetailDialogContent>();
  const { tc } = useTranslation("common");

  const response = useQuery({
    queryKey: [cardData?.cardId],
    queryFn: () => {
      return api.CreditManagement.cardList.getDetails({ id: cardData?.cardId });
    },
    onSuccess: (
      res: Card & { terminateCardRequests: TerminateCardRequest[] }
    ) => {
      if (!res) return;

      const terminationCardRequest = res.terminateCardRequests?.[0];

      setData({
        merchantName: cardData.merchant_name,
        customerNumber: res.customerNumber,
        programName: res.programName,
        cardProfileName: res.cardProfileName,
        cardNumber: res.cardNumber,
        pan: res.panLast4,
        terminationReason: terminationCardRequest?.memo || "",
        createdBy: terminationCardRequest?.createdBy || "",
        creationTime: terminationCardRequest?.createdDate || "",
      });
    },
    enabled: isOpen,
  });

  const dialogConfig = {
    title,
    self: {
      open: isOpen,
      onClose: () => closeDialog(),
    },
    content: <DetailsDialogContent cardData={data} />,
    onConfirm: () => {},
    onCancel: () => closeDialog(),
    isLoadingDialog: true,
    isConfirmHidden: true,
    size: "md" as any,
    customCancelButtonText: tc("close"),
  };

  if (response.isLoading) return <LoadingDialog forceOpen={true} />;

  return <DialogInOne {...dialogConfig} />;
};

export default DetailsDialog;
