import React from 'react';
import DialogWithOuterBox from '../../../../../components/DialogWithOuterBox';
import Content from './components/Content';
import { title } from 'process';

interface AssignCardProfileRequestDialogProps{
    title: string;
    isOpen: boolean;
    closeDialog: () => void;
    refreshTable: () => void;
}

const AssignCardProfileRequestDialog:React.FC<AssignCardProfileRequestDialogProps> = ({
    title,
    isOpen,
    closeDialog,
    refreshTable
}) => {
    return (
        <>
            <DialogWithOuterBox
                isOpen={isOpen}
                title={title}
                closeDialog={closeDialog}
                maxWidth='600px'
            >
                <Content closeDialog={closeDialog} refreshTable={refreshTable}/>
            </DialogWithOuterBox>
        </>
    )
}

export default AssignCardProfileRequestDialog;