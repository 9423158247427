import { EnumAllE6TransactionSubType } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Statement";
import { isNil } from "lodash";

export const getTransactionSubTypeTranslateKey = (
  transactionSubType: number
) => {
  if (isNil(transactionSubType)) {
    return "";
  }

  if (
    String(transactionSubType).slice(String(transactionSubType).length - 2) ===
    "99"
  ) {
    return "Others";
  }

  return EnumAllE6TransactionSubType[transactionSubType]
    ? EnumAllE6TransactionSubType[transactionSubType].split("_")[1]
    : `${EnumAllE6TransactionSubType[transactionSubType]}`;
};
