import { SingleSelection } from "../../../../../components";
import { SelectChangeEvent } from "../../../../../components/MuiGenerals";
import { useTranslation } from "../../../../../hooks";
import { useGetE6Program } from "../../../../../hooks/query/useE6Program";

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const E6ProgramNameSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc } = useTranslation("common");
  const { e6ProgramListEnum } = useGetE6Program();

  const ProgramEnum = e6ProgramListEnum;

  return (
    <SingleSelection
      label={label || tc("phSelection", { fieldName: tc("e6programName") })}
      value={value || ""}
      clearSelect={onClear}
      onChange={onChange}
      enumData={ProgramEnum}
      nameFn={(item) => item}
    />
  );
};

export default E6ProgramNameSingleSelection;
