import { useState } from 'react';

type UseCollapsibleFilter = (props?: { isDefaultExpanded: boolean }) => {
  isCollapse: boolean;
  toggleFilterCollapse: () => void;
};

const useCollapsibleFilters: UseCollapsibleFilter = (props) => {
  const defaultState = !props?.isDefaultExpanded;
  const [isCollapse, setIsCollapse] = useState(defaultState);

  const toggleFilterCollapse = () => {
    setIsCollapse(!isCollapse);
  };

  return {
    isCollapse,
    toggleFilterCollapse,
  };
};

export default useCollapsibleFilters;
