import { useEffect } from "react";
import { FilterTable } from "../../../components/Layout";
import { useZusTranslatePrefixStore } from "../../../zustand/store";
import { translatePrefix, useZusParams } from "./config";
import ViewFilter from "./ViewFilter";
import ViewTable from "./ViewTable";

function SuspiciousTransactionReport() {
    const { setTranslatePrefix } = useZusTranslatePrefixStore();

    const zusParams = useZusParams();

    useEffect(() => {
        setTranslatePrefix(translatePrefix);
        return () => zusParams.clear();
    }, []);
    return <FilterTable filter={<ViewFilter />} table={<ViewTable />} />;
}

export default SuspiciousTransactionReport;