import React, { useEffect } from "react";
import DataAnalysisBoard from "../../../../features/DataAnalysis/components/DataAnalysisBoard";
import { useZusTranslatePrefixStore } from "../../../../zustand/store";
import { EnumChartType } from "../../../../features/DataAnalysis/components/ChartBoard/types/EnumChartType";
import {
  ChartDataType,
  IChartBoardChart,
} from "../../../../features/DataAnalysis/components/ChartBoard";
import api from "../../../../api";
import { useZusParams, translatePrefix, ColumnKeys, Columns } from "./config";
import useDataAnalysis from "../../../../features/DataAnalysis/helper/useDataAnalysis";
import { calculateAmountCountPerPurchase } from "../../../../features/DataAnalysis/helper/calculateAmountCountPerPurchase";
import { convertCategoryGraphDataToLineChartData } from "../../../../features/DataAnalysis/helper/convertCategoryGraphDataToLineChartData";
import { displayAmountCurrying } from "../../../../utils/helper";

interface IProps {}

const ByCountry: React.FC<IProps> = (props) => {
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const zusParams = useZusParams();
  const toDisplayAmount = displayAmountCurrying(0, 2);

  useEffect(() => setTranslatePrefix(translatePrefix), []);
  const tableMapping = (item: any) => {
    const mappedResult = [
      [ColumnKeys.country, item?.category],
      [
        ColumnKeys.consumptionAmountCountHkd,
        toDisplayAmount(Math.abs(Number(item?.totalAmount))),
      ],
      [ColumnKeys.numberOfConsumptionCount, item?.totalCount],
      [
        ColumnKeys.amountCountPerPurchase,
        calculateAmountCountPerPurchase(item?.totalAmount, item?.totalCount),
      ],
    ];
    return mappedResult;
  };

  const {
    t,
    data,
    graphData,
    isGraphDataLoading,
    isDataLoading,
    tableResultMappingFunction,
    reloadData,
    reloadTableData,
  } = useDataAnalysis({
    translatePrefix,
    zusParams,
    columnsKey: Columns,
    graphApi: api.DataAnalysis.Consumption.byCountry.getGraph,
    dataApi: api.DataAnalysis.Consumption.byCountry.getData,
    tableMapping,
    reloadTableTarget: "category",
  });

  const { amountGraphData, amountGraphLine, countGraphData, countGraphLine } =
    convertCategoryGraphDataToLineChartData(graphData, { zusParams });

  const charts: IChartBoardChart[] = [
    {
      type: EnumChartType.LineChart,
      data: amountGraphData,
      line: amountGraphLine,
      dataKey: "value",
      nameKey: "name",
      title: t("consumptionAmountCountByCountryOverTime"),
      width: 200,
      height: 400,
      isLoading: isGraphDataLoading,
      xs: 12,
      md: 6,
      legendAlign: "center",
      dataType: ChartDataType.AMOUNT,
    },
    {
      type: EnumChartType.HorizontalStackBarChart,
      data: graphData.amountGraph || [],
      dataKey: "result",
      nameKey: "category",
      title: t("consumptionAmountCountByCountry"),
      width: 200,
      height: 400,
      isLoading: isGraphDataLoading,
      xs: 12,
      md: 6,
      legendAlign: "center",
      dataType: ChartDataType.AMOUNT,
    },
    {
      type: EnumChartType.LineChart,
      data: countGraphData,
      line: countGraphLine,
      dataKey: "value",
      nameKey: "name",
      title: t("numberOfConsumptionCountByCountryOverTime"),
      width: 200,
      height: 400,
      isLoading: isGraphDataLoading,
      xs: 12,
      md: 6,
      legendAlign: "center",
      dataType: ChartDataType.COUNT,
    },
    {
      type: EnumChartType.HorizontalStackBarChart,
      data: graphData.countGraph || [],
      dataKey: "result",
      nameKey: "category",
      title: t("numberOfConsumptionCountByCountry"),
      width: 200,
      height: 400,
      isLoading: isGraphDataLoading,
      xs: 12,
      md: 6,
      legendAlign: "center",
      dataType: ChartDataType.COUNT,
    },
  ];

  return (
    <DataAnalysisBoard
      charts={charts}
      tableData={data}
      tableColumnKeys={Columns}
      tableResultMappingFunction={tableResultMappingFunction}
      zusParams={zusParams}
      translatePrefix={translatePrefix}
      isChartLoading={isGraphDataLoading}
      reloadGraphFunction={reloadData}
      reloadTableFunction={reloadTableData}
    />
  );
};

export default ByCountry;
