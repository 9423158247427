import React from "react";

import AutoComplete, {
  AutoCompleteProps,
} from "../../../../components/AutoComplete";
import { getCardProfileNameOptions } from "./helpers/getCardProfileNameOptions";
import { partialSearchCardProfileName } from "./helpers/partialSearchCardProfileName";
import { useTranslation } from "../../../../hooks";

interface ICardProfileNameAutoCompleteProps extends Partial<AutoCompleteProps> {
  customOnInputChange: (...args: any[]) => void;
}

const CardProfileNameAutoComplete = (
  props: ICardProfileNameAutoCompleteProps
) => {
  const { value, disabled, customOnInputChange, noOptionText, label } = props;

  const [options, setOptions] = React.useState<string[]>([]);

  const { tc } = useTranslation("common");

  const handleOnInputChange = async (value: string) => {
    customOnInputChange && customOnInputChange(value);

    if (value) {
      const cardProfileNameSearchResult = await partialSearchCardProfileName({
        cardProfileName: value,
      });

      if (!cardProfileNameSearchResult) {
        return;
      }

      const { rows: cardProfileNames } = cardProfileNameSearchResult || {};

      const cardProfileNameOptions =
        getCardProfileNameOptions(cardProfileNames);

      setOptions(cardProfileNameOptions);
    }
  };

  return (
    <AutoComplete
      triggerLength={1}
      disabled={disabled}
      label={label || tc("phInputField", { fieldName: tc("cardProfileName") })}
      value={value}
      onInputChange={(_, value) => handleOnInputChange(value)}
      options={options}
      setOptions={setOptions}
      noOptionText={noOptionText || tc("noResultFound")}
    />
  );
};

export default CardProfileNameAutoComplete;
