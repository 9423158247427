import { useEffect, useState } from "react";

import api from "../../api";

// useGetCardProfileNameV2 currently only used in Pre Create Card Request
export function useGetCardProfileNameV2(dependencyList: {
  merchantId: string;
  programName: string;
}) {
  const { merchantId, programName } = dependencyList;
  const [cardProfileObj, setCardProfileObj] = useState<Record<string, string>>(
    {}
  );

  useEffect(() => {
    if (!merchantId || !programName) {
      return setCardProfileObj({});
    }

    const fetch = async () => {
      const res = await api.CreditPortal.getCardProfileNameV2({
        merchantId,
        programName,
      });

      if (!res) {
        return;
      }

      const cardProfile = res.reduce(
        (acc, { name }) => ({ ...acc, [name]: name }),
        {}
      );

      setCardProfileObj(cardProfile);
    };

    fetch();
  }, [merchantId, programName]);

  return cardProfileObj;
}
