import React from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { EnumMerchantCardProfileStatus } from '@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master';
import { useTranslation } from '../../../../../hooks';




interface IProps {
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
}

const StatusRadio = (props: IProps) => {
  const { value, onChange } = props;
   const { t } = useTranslation("assignCardProfile");

  return (
    <>
      <RadioGroup row value={value} onChange={onChange}>
      {Object.entries(EnumMerchantCardProfileStatus).map(([key, val], index) => (
        isNaN(Number(key)) && (
          <FormControlLabel
            key={index}
            value= {val}
            control={<Radio />}
            label={t(key)} 
          />
        )
      ))}
    </RadioGroup>
    </>
  );
};

export default StatusRadio;