import React from 'react';

import { Box, SelectChangeEvent } from '../../../../../../components/MuiGenerals';
import { SingleSelection } from '../../../../../../components';
import FlexCenterBox from '../../../../../../components/Layout/FlexCenterBox';
import Row from './Row';

import { EnumCardReissueReason } from '@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master';
import { useTranslation } from '../../../../../../hooks';
import { translatePrefix } from '../../../config';
import { ICardData } from '../../../types/ICard';
import getReissueReasonLogicField from '../helpers/getReissueReasonLogicField';
import { updateDefaultReissueBaseOnReissueReason } from '../helpers/updateDefaultReissueBaseOnReissueReason';

interface ReissueCardDialogContentProps {
    cardData: ICardData;
    reissueField: IReissueInput;
    updateField: (fieldName: string, value: string| boolean) => void;
}

    
const ReissueCardDialogContent: React.FC<ReissueCardDialogContentProps> = ({cardData, reissueField, updateField}) => {
    const { t, tc } = useTranslation(translatePrefix);
    return (
        <Box>
            <FlexCenterBox isColumn style={{ marginTop: "24px" }}>
                <Row title={t("merchant_name")} content={cardData?.merchant_name} />
                <Row title={t("customer_no")} content={cardData?.customer_no} />
                <Row title={t("program_name")} content={cardData?.program_name} />
                <Row title={t("card_no")} content={cardData?.card_no} />
                <Row title={t("reissue_reason")} content={
                        <SingleSelection
                            sx={{ formControl: { width: "100%" } }}
                            label={tc("phSelection", { fieldName: t("reissue_reason") })}
                            value={reissueField.reissueReason as string}
                            onChange={(e: SelectChangeEvent) => {
                                updateField("reissueReason", e.target.value);
                                updateDefaultReissueBaseOnReissueReason(e, updateField);
                            }
                            }
                            clearSelect={() =>
                                updateField("reissueReason", "")
                            }
                            enumData={EnumCardReissueReason}
                        />} 
                />
                {
                    reissueField.reissueReason !== "" && getReissueReasonLogicField(reissueField?.reissueReason, t, tc, reissueField, updateField)
                }
            </FlexCenterBox>    
        </Box>
    )
}

export default ReissueCardDialogContent;
