import { ChartBoardCategory, ChartDataType } from "../../../../features/DataAnalysis/components/ChartBoard";
import { useTranslation } from "../../../../hooks";
import { COLOR } from "../../../../utils/config";
import { roundDownAmount } from "../../helpers/roundDownAmount";
import {
  BAR_CHART_TOOLTIP_AMOUNT_NAME_KEY,
  BAR_CHART_TOOLTIP_COUNT_NAME_KEY,
} from "../constants";

interface ICustomTooltipProps {
  active?: boolean;
  payload?: any;
  label?: string;
  dataType?: ChartDataType;
  chartBoardCategory?:  ChartBoardCategory;
}

const TooltipWrapperStyle = {
  backgroundColor: COLOR.Chart.BarStackChart.TooltipContentBackground,
  padding: "2px 12px",
  borderRadius: "5px",
};

const LabelStyle = {
  color: COLOR.Chart.BarStackChart.LabelColor,
};

const CustomTooltip: React.FC<ICustomTooltipProps> = (props) => {
  const { active, payload, dataType, chartBoardCategory } = props;
  const { tpc, tmccc } = useTranslation("enumConstants");
  if (active && payload && payload.length) {
    return (
      <div style={TooltipWrapperStyle}>
        {payload.map((entry: any, index: any) => {
          const getTitle = () => {
            switch (chartBoardCategory) {
              case ChartBoardCategory.MCC:
                  return tmccc(entry.payload?.category);
              case ChartBoardCategory.PAYMENT_METHOD_CODE:
                  return tpc(entry.payload?.category)
              default:
                  return (
                    entry.payload.category || entry.payload.customer_number || ""
                  );
            }
          };

          const getLabel = () => {
            return dataType === ChartDataType.AMOUNT
              ? BAR_CHART_TOOLTIP_AMOUNT_NAME_KEY
              : BAR_CHART_TOOLTIP_COUNT_NAME_KEY;
          };

          const title = getTitle();

          const label = getLabel();
          const value = roundDownAmount(entry.value, dataType);

          const labelValuePair = `${label}: ${value}`;

          return (
            <div key={`item-${index}`}>
              <p>{title}</p>

              <p style={LabelStyle}>{labelValuePair}</p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
