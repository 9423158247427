import { EnumAdjustmentSubType } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";

import { SingleSelection } from "../../../../../components";
import { SelectChangeEvent } from "../../../../../components/MuiGenerals";
import { useTranslation } from "../../../../../hooks";

interface IProps {
  label?: string;
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
  adjustmentType: string;
}

const AdjustmentSubTypeSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear, adjustmentType } = props;
  const { tc } = useTranslation("common");

  const noAdjustmentType = !adjustmentType;

  const getSubTypeEnum = () => {
    if (noAdjustmentType) {
      return { [tc("selectAdjustmentTypeFirst")]: "" };
    }

    return EnumAdjustmentSubType[
      Number(adjustmentType) as keyof typeof EnumAdjustmentSubType
    ];
  };

  const SubTypeEnum = getSubTypeEnum();
  const selectionNameFn = noAdjustmentType ? (name: string) => name : undefined;

  return (
    <SingleSelection
      label={label || tc("phSelection", { fieldName: tc("subType") })}
      value={value}
      onChange={onChange}
      clearSelect={onClear}
      enumData={SubTypeEnum}
      nameFn={selectionNameFn}
    />
  );
};

export default AdjustmentSubTypeSingleSelection;
