import { MerchantCardProfile } from "@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal";
import axios from "../axiosInstance";
import { EnumE6CardType } from "@wallet-manager/pfh-node-def-types/dist/src/E6Enum";
import { CardProfileDetails, UpdateCardProfileFields } from "../types";

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(MerchantCardProfile.CardProfileSetting.getAll, input);
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(MerchantCardProfile.CardProfileSetting.export, input);
}

const createCardProfile = async (
  params: UpdateCardProfileFields,
  config = {}
) => {
  const res = await axios.post<never, any>(
    MerchantCardProfile.CardProfileSetting.create,
    { ...params, embossedName: params.embossedName || null },
    config
  );
  return res;
};

const editCardProfile = async (
  params: UpdateCardProfileFields,
  config = {}
) => {
  const res = await axios.post<never, any>(
    MerchantCardProfile.CardProfileSetting.edit,
    { ...params, embossedName: params.embossedName || null },
    config
  );
  return res;
};

const getCardProfileDetails = async (params: {
  e6ProgramName: string;
  cardProfileName: string;
}) => {
  const res = await axios.get<any, CardProfileDetails>(
    MerchantCardProfile.CardProfileSetting.detail,
    {
      params,
    }
  );

  return res;
};

const getIinList = async () => {
  const res = await axios.get(MerchantCardProfile.CardProfileSetting.getIIN);

  return res;
};

export default {
  getAll,
  getExport,
  createCardProfile,
  editCardProfile,
  getCardProfileDetails,
  getIinList,
};
