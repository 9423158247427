import { MultipleSelection } from "../../../../../components";
import { useTranslation } from "../../../../../hooks";
import { useGetE6Program } from "../../../../../hooks/query/useE6Program";

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const E6ProgramNameMultipleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { e6ProgramListEnum } = useGetE6Program();

  const ProgramEnum = e6ProgramListEnum;

  const { tc } = useTranslation("common");

  let selectValue = value || [];

  if (!Array.isArray(selectValue)) {
    selectValue = [value];
  }

  return (
    <MultipleSelection
      label={label || tc("phSelection", { fieldName: tc("e6programName") })}
      value={selectValue}
      onChange={onChange}
      clearSelect={onClear}
      enumData={ProgramEnum}
      nameFn={(item) => item}
    />
  );
};

export default E6ProgramNameMultipleSelection;
