import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IprogramList } from "../api/types";
import { RootState } from "./store";

interface IpropramByMerchantList {
  [merchantId: string]: Record<string, string>;
}

interface IprogramListState {
  allProgramList: Record<string, string>;
  propramByMerchantList: IpropramByMerchantList;
  rawData: IprogramList[];
}

const initialState: IprogramListState = {
  allProgramList: {},
  propramByMerchantList: {},
  rawData: [],
};

export const programListSlice = createSlice({
  name: "programList",
  initialState,
  reducers: {
    setProgramList: (state, action: PayloadAction<IprogramList[]>) => {
      if (!action.payload) {
        return;
      }

      state.rawData = action.payload;

      state.allProgramList = action.payload.reduce(
        (acc: Record<string, string>, { programName }) => ({
          ...acc,
          [programName]: programName,
        }),
        {}
      );
      state.propramByMerchantList = action.payload.reduce(
        (acc: IpropramByMerchantList, { programName, merchantId }) => {
          const isMerchantIdExist = !!acc[merchantId];

          const merchantIdValue = isMerchantIdExist
            ? { ...acc[merchantId], [programName]: programName }
            : { [programName]: programName };

          return {
            ...acc,
            [merchantId]: merchantIdValue,
          };
        },
        {}
      );
    },
  },
});

export const { setProgramList } = programListSlice.actions;

export const selectProgramList = (state: RootState) => state.programList;
export default programListSlice.reducer;
