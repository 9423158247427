import { QueryClient } from "react-query";

import { generateNonce, hexToRGB, rgbaFn } from "../utils/helper";
import { defaultIntervalPendingOperationsCount } from "./constant";
import { BarChart, LineChart } from "recharts";

export const dateTimeInFormat = "yyyy-MM-dd HH:mm:ss";

export const dateInFormat = "yyyy-MM-dd";

export const dateInYYYYMM = "yyyy-MM";

export const nonce = generateNonce();

export const LAST_ACTIVE_UPPER_LIMIT_MINS = Number(
  process.env.REACT_APP_LAST_ACTIVE_UPPER_LIMIT_MINS || "30"
);

export const INTERVAL_PENDING_OPERATIONS_COUNT =
  process.env.REACT_APP_INTERVAL_PENDING_OPERATIONS_COUNT ||
  defaultIntervalPendingOperationsCount; // second

export const ENDPOINT_HOST = process.env.REACT_APP_ENDPOINT_HOST;

export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

//https://react-query-v3.tanstack.com/guides/initial-query-data#staletime-and-initialdataupdatedat
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 200, //ms
    },
  },
});

export const initZusParams = {
  page: 0,
  pageSize: 20,
} as const;

const PALETTE = {
  Button3Text: "#FFFFFF",

  SecondaryVariant: "#333333",

  ChartColor1: "#fee391",
  ChartColor2: "#fec450",
  ChartColor3: "#ff7400",
  ChartColor4: "#FFA700",
  ChartColor5: "#ffce01",

  ChartColor6: "#FFDE1B",

  // new color (for line chart)
  ChartColor7: "#FFFF00",
  ChartColor8: "#FFE3CA",
  ChartColor9: "#FF4444",
  ChartColor10: "#FF93D0",
  ChartColor11: "#FF8400",
  // end of new color

  DashBoardColor1: "#20D3FD",
  GreyColor: "#B0B0B0",
  DotColor: "#fec450",
};

export const COLOR = {
  background: {
    primary: {
      hex: "#323439",
      rgb: hexToRGB("#323439"),
      rgba: rgbaFn("#323439"),
    },
    secondary: {
      hex: "#515664",
      rgb: hexToRGB("#515664"),
      rgba: rgbaFn("#515664"),
    },
    table: {
      hex: "#555961",
      rgb: hexToRGB("#555961"),
      rgba: rgbaFn("#555961"),
    },
  },
  text: {
    primary: {
      hex: "#FFEEB4",
      rgb: hexToRGB("#FFEEB4"),
      rgba: rgbaFn("#FFEEB4"),
    },
    secondary: {
      hex: "#FFFFFF",
      rgb: hexToRGB("#FFFFFF"),
      rgba: rgbaFn("#FFFFFF"),
    },
  },
  button: {
    primary: {
      hex: "#505660",
      rgb: hexToRGB("#505660"),
      rgba: rgbaFn("#505660"),
    },
  },
  layoutBar: {
    primary: {
      hex: "#231F20",
      rgb: hexToRGB("#231F20"),
      rgba: rgbaFn("#231F20"),
    },
  },
  Chart: {
    DoughnutChart: {
      ChartColor: [
        PALETTE.ChartColor1,
        PALETTE.ChartColor2,
        PALETTE.ChartColor3,
        PALETTE.ChartColor4,
        PALETTE.ChartColor5,
        PALETTE.ChartColor6,
      ],
    },
    LineChart: {
      LineColor: [
        // PALETTE.ChartColor6,
        PALETTE.ChartColor7,
        PALETTE.ChartColor8,
        PALETTE.ChartColor9,
        PALETTE.ChartColor10,
        PALETTE.ChartColor11,
        // PALETTE.ChartColor1,
        // PALETTE.ChartColor2,
        // PALETTE.ChartColor3,
        // PALETTE.ChartColor4,
        // PALETTE.ChartColor5,
        // PALETTE.ChartColor6,
      ],
      CartesianGridColor: PALETTE.GreyColor,
      TooltipContentBackground: PALETTE.SecondaryVariant,
      DotColor: PALETTE.DotColor,
    },
    BarStackChart: {
      ChartColor: [
        PALETTE.ChartColor1,
        PALETTE.ChartColor2,
        PALETTE.ChartColor3,
        PALETTE.ChartColor4,
        PALETTE.ChartColor5,
        PALETTE.ChartColor6,
      ],
      LabelColor: "#FFEEB4",
      CartesianGridColor: PALETTE.GreyColor,
      TooltipContentBackground: PALETTE.SecondaryVariant,
    },
  },
  Dashboard: {
    programAgentBalance: {
      Title: {
        Color: PALETTE.Button3Text,
      },
      Amount: {
        Color: PALETTE.DashBoardColor1,
      },
    },
    label: PALETTE.GreyColor,
  },
};
