import { DialogInOne, OpTextButton } from "../../../../../../components";
import { DialogInOneProps } from "../../../../../../components/DialogInOne";
import { useTranslation } from "../../../../../../hooks";
import ContentBillingHistory from "./ContentBillingHistory";

interface IProps {
  isOpen: boolean
  totalBalance?: string
  handleCloseDialog: () => void
}

export default function DialogBillingHistory(props: IProps) {
  const {
    isOpen,
    totalBalance,
    handleCloseDialog
  } = props;

  const { tc } = useTranslation("customerAccount");

  const CloseBtnEle = () => (
    <OpTextButton text={tc("close")} onClick={handleCloseDialog} />
  );

  const dialogConfig: DialogInOneProps = {
    title: "",
    self: {
      open: isOpen,
      onClose: handleCloseDialog
    },
    content: <ContentBillingHistory totalBalance={totalBalance} />,
    isConfirmHidden: true,
    onConfirm: () => { },
    isCancelHidden: true,
    onCancel: () => { },
    actionButtons: <CloseBtnEle />,
  }
  return <DialogInOne {...dialogConfig} />
}