import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Container } from "@mui/system";
import { EnumCardStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import { EnumE6CardState } from "@wallet-manager/pfh-node-def-types/dist/src/E6Enum";

import {
  MpTextField,
  MpTextFieldMultiple,
  SingleSelection,
} from "../../../components";
import { useDatePicker } from "../../../components/DatePicker";
import { SelectChangeEvent } from "../../../components/MuiGenerals";
import {
  useGetMerchant,
  useGetPartner,
  useGetProgram,
  useTranslation,
} from "../../../hooks";
import { strToStrArrForFilter } from "../../../utils/helper";
import {
  Ifield,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";
import useCollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters";
import { Filter } from "../../../features/common/filterTable/types";
import CollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters";
import FilterSectionActionRow from "../../../features/common/filterTable/layout/FilterSectionActionRow";
import ToggleFilterButton from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton";
import ProgramNameMultipleSelection from "../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection";
import CardProfileNameAutoComplete from "../../../features/Setting/components/CardProfileNameAutoComplete";

function ViewFilter() {
  const { t, tc } = useTranslation(translatePrefix);
  const [searchParams] = useSearchParams();

  const [fields, setFields] = useState<Ifield>({
    ...initFields,
    orderId: searchParams.get("orderId") || "",
  });

  const merchantObj = useGetMerchant();
  const partnerObj = useGetPartner();
  const programObj = useGetProgram();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const zusParams = useZusParams();

  const getParams: () => IsearchParam = () => {
    const {
      customerNumber,
      e6CardState,
      programName: programNames,
      ...rest
    } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } =
      DateObj.LastModifiedTime;

    const customerNumbers = strToStrArrForFilter(customerNumber);

    return {
      ...initZusParams,
      ...rest,
      state: e6CardState ? Number(e6CardState) : "",
      customerNumbers,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
      programNames,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  useEffect(() => {
    if (searchParams.has("orderId")) {
      zusParams.setBody({ ...apiParams, page: 0 });
    }
  }, []);

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.lastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.merchantName,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.merchantName) })}
          value={fields.merchantId}
          onChange={onChange("merchantId")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, merchantId: "" }))
          }
          nameFn={(name) => name}
          isNoSorting
          enumData={merchantObj}
        />
      ),
    },
    {
      labelKey: TK.partnerName,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.partnerName) })}
          value={fields.partnerName}
          onChange={onChange("partnerName")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, partnerName: "" }))
          }
          nameFn={(name) => name}
          enumData={partnerObj}
        />
      ),
    },
    {
      labelKey: TK.programName,
      filter: (
        <ProgramNameMultipleSelection
          value={fields.programName}
          onChange={(value: string[]) =>
            setFields((fields) => ({ ...fields, programName: value }))
          }
          onClear={() =>
            setFields((fields) => ({ ...fields, programName: [] }))
          }
        />
      ),
    },
    {
      labelKey: TK.cardProfileName,
      filter: (
        <CardProfileNameAutoComplete
          value={fields.cardProfileName}
          customOnInputChange={(value) =>
            setFields((f) => ({ ...f, cardProfileName: value }))
          }
        />
      ),
    },
    {
      labelKey: TK.orderId,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.orderId) })}
          value={fields.orderId}
          onChange={onChange("orderId")}
        />
      ),
    },
    {
      labelKey: TK.customerNo,
      filter: (
        <MpTextFieldMultiple
          label={tc("phInputField", { fieldName: t(TK.customerNo) })}
          value={fields.customerNumber}
          onChange={onChange("customerNumber")}
          count={apiParams.customerNumbers.length}
        />
      ),
    },
    {
      labelKey: TK.originalCardNo,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.originalCardNo) })}
          value={fields.originalCardNumber}
          onChange={onChange("originalCardNumber")}
        />
      ),
    },
    {
      labelKey: TK.cardNo,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.cardNo) })}
          value={fields.cardNumber}
          onChange={onChange("cardNumber")}
        />
      ),
    },
    {
      labelKey: TK.pan,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.pan) })}
          value={fields.panLast4}
          onChange={onChange("panLast4")}
        />
      ),
    },
    {
      labelKey: TK.status,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.status) })}
          value={fields.status}
          onChange={onChange("status")}
          clearSelect={() => setFields((fields) => ({ ...fields, status: "" }))}
          enumData={EnumCardStatus}
        />
      ),
    },
    {
      labelKey: TK.reissueOrderId,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.reissueOrderId) })}
          value={fields.reissueOrderId}
          onChange={onChange("reissueOrderId")}
        />
      ),
    },
    {
      labelKey: TK.e6CardState,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.e6CardState) })}
          value={fields.e6CardState}
          onChange={onChange("e6CardState")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, e6CardState: "" }))
          }
          enumData={EnumE6CardState}
        />
      ),
    },
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <FilterSectionActionRow>
        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>

      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}

export default ViewFilter;
