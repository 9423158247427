import { EnumCardStatus } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import { MpTextField } from "../../../../../components";
import FlexCenterBox from "../../../../../components/Layout/FlexCenterBox";
import { Box } from "../../../../../components/MuiGenerals";
import { useTranslation } from "../../../../../hooks";
import { translatePrefix, translateKeyObj as TK } from "../../config";
import { ICardData } from "../../types/ICard";
import Row from "../ReissueCardDialog/components/Row";

interface TerminateCardDialogContentProps {
  cardData: ICardData;
  iTerminateInput: ITerminateInput;
  updateField: (fieldName: string, value: string | boolean) => void;
}

const TerminateCardDialogContent: React.FC<TerminateCardDialogContentProps> = ({
  cardData,
  iTerminateInput,
  updateField,
}) => {
  const { t, tc } = useTranslation(translatePrefix);
  return (
    <Box
      sx={{
        "& .MuiInputLabel-shrink": {
          display: "none",
        },
        "& .MuiFormControl-root": {
          width: "100%",
        },
      }}
    >
      <FlexCenterBox isColumn style={{ marginTop: "24px" }}>
        <Row title={t(TK.merchantName)} content={cardData?.merchant_name} />
        <Row title={t(TK.customerNo)} content={cardData?.customer_no} />
        <Row title={t(TK.programName)} content={cardData?.program_name} />
        <Row
          title={t(TK.cardProfileName)}
          content={cardData?.card_profile_name}
        />
        <Row title={t(TK.cardNo)} content={cardData?.card_no} />
        <Row title={t(TK.pan)} content={cardData?.pan} />
        <Row
          title={t(TK.terminationReason)}
          content={
            <MpTextField
              label={tc("phInputField", { fieldName: t(TK.terminationReason) })}
              value={iTerminateInput.memo}
              onChange={(e) => updateField("memo", e.currentTarget.value)}
            />
          }
          contentStyle={{ width: "80%" }}
        />
      </FlexCenterBox>
    </Box>
  );
};

export default TerminateCardDialogContent;
