import React from "react";

import { MpTextField } from "../../../../../components";
import { useTranslation } from "../../../../../hooks";
import { checkIsPositiveInteger } from "../../../../../utils/helper";

interface IProps {
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
}

const ExpiryTextField = (props: IProps) => {
  const { label, value, onChange } = props;

  const { tc } = useTranslation("common");

  const handleOnChange = (e: React.ChangeEvent<any>) => {
    const value = e.target.value;

    if (!checkIsPositiveInteger(value)) return;

    onChange && onChange(e);
  };

  return (
    <MpTextField
      label={label || tc("phInputField", { fieldName: tc("expiryNoOfMonths") })}
      value={value}
      onChange={handleOnChange}
      inputProps={{ maxLength: 2 }}
    />
  );
};

export default ExpiryTextField;
