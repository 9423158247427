import React, { useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { COLOR } from "../../../utils/config";
import CustomizedDot from "./components/CustomizedDot";
import CustomTooltip from "./components/CustomTooltip";
import CustomXAxisTickFormatter from "./components/CustomXAxisTickFormatter";
import { createIndexXAxisPairs } from "./helpers/createIndexXAxisPairs";
import { ChartDataType, ChartBoardCategory } from "../../../features/DataAnalysis/components/ChartBoard";

interface ILineChartProps {
  data: any[];
  height: number | string;
  lines?: any[];
  isDotDisplay?: boolean;
  xAxisDisplay?: boolean;
  yAxisDisplay?: boolean;
  dataType?: ChartDataType;
  chartBoardCategory?: ChartBoardCategory;
}

const LineChart: React.FC<ILineChartProps> = ({
  data,
  height = 200,
  lines,
  isDotDisplay = false,
  xAxisDisplay = true,
  yAxisDisplay = false,
  dataType,
  chartBoardCategory
}) => {
  return (
    <ResponsiveContainer
      width={"100%"}
      height={height}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AreaChart data={data} margin={{ right: 30 }}>
        <XAxis
          dataKey="xAxisName"
          hide={!xAxisDisplay}
          // REMARKS: "preserveStartEnd" could mess up the index for custom formatter
          interval={data?.length > 14 ? 0 : "preserveStartEnd"}
          tickFormatter={
            data?.length > 14
              ? (tick, index) =>
                  CustomXAxisTickFormatter(
                    tick,
                    index,
                    createIndexXAxisPairs(data)
                  )
              : undefined
          }
          tick={{ fontSize: 11 }}
          dy={10}
        />
        <YAxis hide={!yAxisDisplay} />
        <CartesianGrid
          vertical={false}
          stroke={COLOR.Chart.LineChart.CartesianGridColor}
          strokeOpacity={0.3}
        />
        <Legend
          verticalAlign="bottom"
          wrapperStyle={{
            paddingTop: "10px",
          }}
        />
        {typeof lines === "object" &&
          lines.length &&
          lines?.map((line: any, index: any) => (
            <>
              <Tooltip
                key={index}
                content={<CustomTooltip dataType={dataType} chartBoardCategory={chartBoardCategory}/>}
              />
              <Area
                key={index}
                type="monotone"
                dataKey={line.valueKey}
                stroke={
                  COLOR.Chart.LineChart.LineColor[
                    index % COLOR.Chart.LineChart.LineColor.length
                  ]
                }
                fillOpacity={0}
                fill={
                  COLOR.Chart.LineChart.LineColor[
                    index % COLOR.Chart.LineChart.LineColor.length
                  ]
                }
                connectNulls={false}
                activeDot={{ r: 2 }}
                dot={
                  isDotDisplay && (
                    <CustomizedDot
                      dotColor={
                        COLOR.Chart.LineChart.LineColor[
                          index % COLOR.Chart.LineChart.LineColor.length
                        ]
                      }
                    />
                  )
                }
              />
            </>
          ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
