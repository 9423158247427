import React, { useEffect, useState } from "react";

import api from "../../../../../../api";
import { Box } from "../../../../../../components/MuiGenerals";
import {
  useAlerting,
  usePermission,
  useTranslation,
} from "../../../../../../hooks";
import useCustomerInfoSearch from "../../../../../../hooks/query/useCustomerInfoSearch";
import useValidation from "../../../../../../hooks/useValidation";
import { ValidationConfigProps } from "../../../../../../hooks/useValidation/types";
import { requiredTextInputLength } from "../../../../../../utils/validators/requiredTextInputLength";
import { requiredValidator } from "../../../../../../utils/validators/requiredValidator";
import { PermissionKey, translatePrefix } from "../../../config";
import ActionButtons from "./ActionButtons";
import Form from "./Form";

interface IContentProps {
  closeDialog: () => void;
  refreshTable: () => void;
}

enum ValidationFieldKeys {
  CustomerName = "customerName",
  CardProfileName = "cardProfileName",
  remark = "remark",
}

const Content: React.FC<IContentProps> = ({ closeDialog, refreshTable }) => {
  const { t, tc, translate } = useTranslation(translatePrefix);
  const initField = {
    customerNumber: "",
    merchantId: "",
    cardProfileName: "",
    remark: "",
  };
  const [newCardFields, setNewCardFields] = useState(initField);
  const { merchant, loading } = useCustomerInfoSearch(
    newCardFields.customerNumber
  );
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();

  const validationConfig: ValidationConfigProps = [
    {
      key: ValidationFieldKeys.CustomerName,
      value: newCardFields.customerNumber,
      validators: [
        requiredValidator(
          translate("validation.inputRequired", {
            fieldName: t("customer_no"),
          })
        ),
      ],
    },
    {
      key: ValidationFieldKeys.CardProfileName,
      value: newCardFields.cardProfileName,
      validators: [
        requiredValidator(
          translate("validation.inputRequired", {
            fieldName: t("card_profile_name"),
          })
        ),
      ],
    },
    {
      key: ValidationFieldKeys.remark,
      value: newCardFields.remark,
      validators: [
        requiredValidator(
          translate("validation.inputRequired", {
            fieldName: t("remark"),
          })
        ),
        requiredTextInputLength(
          translate("validation.inputLength", {
            fieldName: t("remark"),
            min: 0,
            max: 256,
          }),
          {
            min: 0,
            max: 256,
          }
        ),
      ],
    },
  ];
  const { validate } = useValidation(validationConfig);

  const onConfirm = async () => {
    if (!hasPermission(PermissionKey.CreateRequest)) {
      return alerting("warning", tc("alert_no_permission"));
    }

    const { allErrors } = validate();

    if (allErrors.length > 0) {
      return alerting("warning", allErrors[0]);
    }

    if (!merchant || !merchant.merchantId) {
      return alerting("warning", t("customerNumberNotFound"));
    }

    const res = await api.CreditManagement.cardOrderRequest.postCreateCard({
      customerNumber: newCardFields.customerNumber,
      cardProfileName: newCardFields.cardProfileName,
      merchantId: merchant.merchantId,
      remark: newCardFields.remark,
    });

    if (!res) return;
    alerting("success", t("create_request_success"));
    refreshTable();
    closeDialog();
  };

  useEffect(() => {
    if (!loading && !merchant?.merchantId && newCardFields.customerNumber) {
      alerting("warning", t("customerNumberNotFound"));
    }
  }, [merchant]);

  return (
    <>
      <Box>
        <Box
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form
            loading={loading}
            merchant={merchant}
            newCardFields={newCardFields}
            setNewCardFields={setNewCardFields}
          />
          <ActionButtons onConfirm={onConfirm} closeDialog={closeDialog} />
        </Box>
      </Box>
    </>
  );
};

export default Content;
