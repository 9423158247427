import { useEffect, useState } from "react";

import api from "../../api";
import { getCardProfileNameOptions } from "../../features/Setting/components/CardProfileNameAutoComplete/helpers/getCardProfileNameOptions";

export function useGetCardProfileNameFromE6Program(dependencyList: {
  e6ProgramName: string;
}) {
  const { e6ProgramName } = dependencyList;
  const [cardProfileObj, setCardProfileObj] = useState<Record<string, string>>(
    {}
  );

  useEffect(() => {
    if (!e6ProgramName) {
      return setCardProfileObj({});
    }
    const fetch = async () => {
      const res = await api.CreditPortal.getProgramCardProfileFromE6Program({
        e6ProgramName,
      });
      
      if (!res) {
        console.warn("Invalid or empty response from API.");
        return setCardProfileObj({}); // Or handle the error appropriately
      }
      console.log('card profile res: ', res['rows'])

      const cardProfileNames = res['rows'] || {};

      const cardProfileNameOptions: string[] = cardProfileNames.map((item) => item.cardProfileName);
      
      console.log('cardProfileNames options : ', cardProfileNameOptions)

      const options: Record<string, string> = cardProfileNameOptions.reduce((acc: Record<string, string>, curr: string) => {
        acc[curr] = curr;
        return acc;
      }, {});

      setCardProfileObj(options);
    };

    fetch();
  }, [e6ProgramName]);

  return cardProfileObj;
}
