import { IPartialSearchCardProfileNameResponse } from "../../../../../api/types";

export const getCardProfileNameOptions = (
  data: IPartialSearchCardProfileNameResponse[] | undefined
) => {
  if (!data || data.length === 0) return [];

  const cardProfileNameOptions = data.map((item) => item.cardProfileName);

  return cardProfileNameOptions;
};
