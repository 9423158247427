import { MerchantCardProfile } from "@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal";
// import { CreateCardProfileFields } from "../../views/Setting/CardProfileSetting/components/CreateCardProfileDialog/types";
import axios from "../axiosInstance";
import { ApiConfig } from "../types";

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  //   TODO:CC-2125
  return await axios.get(MerchantCardProfile.AssignCardProfile.getAll, input);
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  //   TODO:CC-2125
  return await axios.get(MerchantCardProfile.AssignCardProfile.export, input);
}

async function postEdit(
  params: {
    merchantId: number;
    programName: string;
    cardProfileName: string;
    status: number;
  },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(MerchantCardProfile.AssignCardProfile.edit, input);
}

async function postAssign(
  params: {
    merchantId: number;
    programName: string;
    cardProfileName: string;
  },
  config?: ApiConfig
): Promise<any> {
  const input = {
    ...params,
  };

  return await axios.post(
    MerchantCardProfile.AssignCardProfile.assignProfile,
    input,
    config
  );
}

export default {
  getAll,
  getExport,
  postEdit,
  postAssign,
};
