import React from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { COLOR } from "../../../utils/config";
import CustomTooltip from "./components/CustomTooltip";
import { ChartDataType, ChartBoardCategory } from "../../../features/DataAnalysis/components/ChartBoard";

interface HorizontalStackBarChartProps {
  data: any[];
  barValueKey: string[];
  valueKey?: string;
  nameKey: string;
  legendAlign?: "left" | "center" | "right";
  width?: number;
  height?: number;
  dataType?: ChartDataType;
  chartBoardCategory?: ChartBoardCategory;
}

const HorizontalStackBarChart: React.FC<HorizontalStackBarChartProps> = ({
  data,
  legendAlign = "center",
  nameKey,
  valueKey = "value",
  barValueKey,
  width = 400,
  height = 400,
  dataType,
  chartBoardCategory,
}) => {
  const maxValue = Math.max(
    ...data.map((item) => Math.max(...barValueKey.map((key) => item[key])))
  );
  return (
    <ResponsiveContainer
      width={"100%"}
      height={height}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <BarChart
        layout="vertical"
        key={Math.random()}
        data={data || []}
        margin={{ top: 5, right: 0, left: 20, bottom: 5 }}
      >
        <XAxis
          type="number"
          dataKey={valueKey}
          axisLine={false}
          domain={[0, maxValue]}
        />
        <YAxis
          dataKey={nameKey}
          type="category"
          stroke={COLOR.Chart.BarStackChart.LabelColor}
          tickFormatter={(value: string, index: number) => {
            if (value.length > 7) {
              return value.slice(0, 7) + "...";
            }
            return value;
          }}
        />
        <Tooltip
          cursor={false}
          contentStyle={{
            backgroundColor: COLOR.Chart.LineChart.TooltipContentBackground,
          }}
          content={<CustomTooltip dataType={dataType} chartBoardCategory={chartBoardCategory}/>}
        />
        <CartesianGrid
          horizontal={false}
          stroke={COLOR.Chart.BarStackChart.CartesianGridColor}
          strokeOpacity={0.3}
        />

        {barValueKey.length > 1 && (
          <Legend align={legendAlign} iconType="circle" />
        )}
        {barValueKey.map((item, index) => (
          <Bar
            key={index}
            dataKey={item}
            stackId="a"
            fill={
              COLOR.Chart.BarStackChart.ChartColor[
                index % COLOR.Chart.BarStackChart.ChartColor.length
              ]
            }
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalStackBarChart;
