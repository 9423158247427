import React from "react";

import { MpTextField, SingleSelection } from "../../../../../../components";
import { Box, Typography } from "../../../../../../components/MuiGenerals";
import { useGetProgram, useTranslation } from "../../../../../../hooks";
import { translatePrefix } from "../../../config";
import MerchantNameSingleSelection from "../../../../../../features/common/filterTable/components/Filters/MerchantNameSingleSelection";
import { programInfoSearchReturnText } from "../helper/assignCardProfileDialogUIHelper";
import { useAppSelector } from "../../../../../../reducer/hooks";
import { selectMerchantList } from "../../../../../../reducer/merchantListSlice";
import { selectProgramList } from "../../../../../../reducer/programListSlice";
import CardProfileNameSingleSelection from "./CardProfileNameSingleSelection";

interface FormProps {
  assignCardProfileFields: any;
  setAssignCardProfileFields: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean;
  merchant: any;
}

const Form: React.FC<FormProps> = ({
  assignCardProfileFields,
  setAssignCardProfileFields,
  loading,
  merchant,
}) => {
  const { t, tc } = useTranslation(translatePrefix);
  const { merchantId } = assignCardProfileFields;
  const programObj = useGetProgram(merchantId) || {};
  const merchantList = useAppSelector(selectProgramList);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        "& .MuiInputLabel-shrink": {
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginBottom: "14px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            width: "30%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {t("merchant_name")}
        </Typography>
        <MerchantNameSingleSelection
          value={assignCardProfileFields.merchantId}
          label={tc("phSelection", { fieldName: t("programName") })}
          onChange={(e) => {
            setAssignCardProfileFields({
              ...assignCardProfileFields,
              merchantId: e.target.value,
              programName: "",
              cardProfileName: "",
              e6ProgramName: "",
            });
          }}
          onClear={() => {
            setAssignCardProfileFields({
              ...assignCardProfileFields,
              merchantId: "",
              programName: "",
              cardProfileName: "",
              e6ProgramName: "",
            });
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          variant="h6"
          sx={{ width: "30%", display: "flex", alignItems: "center" }}
        >
          {t("program_name")}
        </Typography>
        <Box
          sx={{
            // display: "flex",
            width: "70%",
            // flexDirection: "column",
            position: "relative",
            marginBottom: "15px",
          }}
        >
          <SingleSelection
            sx={{ formControl: { width: "100%" } }}
            label={tc("phSelection", { fieldName: t("programName") })}
            value={assignCardProfileFields.programName}
            onChange={(e) => {
              setAssignCardProfileFields({
                ...assignCardProfileFields,
                programName: e.target.value,
                e6ProgramName: programInfoSearchReturnText(
                  e.target.value,
                  assignCardProfileFields.merchantId,
                  merchantList
                ).e6ProgramName,
                cardProfileName: "",
              });
            }}
            clearSelect={() => {
              setAssignCardProfileFields({
                ...assignCardProfileFields,
                programName: "",
                cardProfileName: "",
                e6ProgramName: "",
              });
            }}
            nameFn={(name) => name}
            enumData={programObj}
          />
          <Typography
            sx={{
              color: "#808080",
              fontSize: "0.65rem",
              position: "absolute",
              bottom: -12,
              left: 12,
            }}
          >
            {t("e6_program_name") +
              ": " +
              assignCardProfileFields.e6ProgramName}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          variant="h6"
          sx={{ width: "30%", display: "flex", alignItems: "center" }}
        >
          {t("card_profile_name")}
        </Typography>
        <CardProfileNameSingleSelection
          value={assignCardProfileFields.cardProfileName}
          onChange={(e) => {
            setAssignCardProfileFields({
              ...assignCardProfileFields,
              cardProfileName: e.target.value,
            });
          }}
          onClearSelect={() => {
            setAssignCardProfileFields({
              ...assignCardProfileFields,
              cardProfileName: "",
            });
          }}
          e6ProgramName={assignCardProfileFields?.e6ProgramName}
        />
      </Box>
    </Box>
  );
};

export default Form;
