import FlexCenterBox from "../../../../../components/Layout/FlexCenterBox";
import { Box } from "../../../../../components/MuiGenerals";
import { useTranslation } from "../../../../../hooks";
import { toDisplayTime } from "../../../../../utils/helper";
import { translatePrefix, translateKeyObj as TK } from "../../config";
import Row from "../ReissueCardDialog/components/Row";
import { IDetailDialogContent } from "./type";

interface DetailDialogContentProps {
  cardData: IDetailDialogContent | undefined;
}

const DetailDialogContent: React.FC<DetailDialogContentProps> = ({
  cardData,
}) => {
  const { t } = useTranslation(translatePrefix);

  return (
    <Box>
      <FlexCenterBox isColumn style={{ marginTop: "24px" }}>
        <Row title={t(TK.merchantName)} content={cardData?.merchantName} />
        <Row title={t(TK.customerNo)} content={cardData?.customerNumber} />
        <Row title={t(TK.programName)} content={cardData?.programName} />
        <Row
          title={t(TK.cardProfileName)}
          content={cardData?.cardProfileName}
        />
        <Row title={t(TK.cardNo)} content={cardData?.cardNumber} />
        <Row title={t(TK.pan)} content={cardData?.pan} />
        <Row
          title={t(TK.terminationReason)}
          content={cardData?.terminationReason}
          contentStyle={{ wordBreak: 'break-word' }}
        />
        <Row title={t(TK.createdBy)} content={cardData?.createdBy} />
        <Row
          title={t(TK.creationTime)}
          content={toDisplayTime(cardData?.creationTime || "")}
        />
      </FlexCenterBox>
    </Box>
  );
};

export default DetailDialogContent;
