import { useQuery } from "react-query";

import api from "../../api";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import {
  IE6ProgramList,
  selectE6ProgramList,
  setE6ProgramList,
} from "../../reducer/e6ProgramListSlice";

export async function useFetchE6Program() {
  const dispatch = useAppDispatch();

  useQuery("e6ProgramList", {
    staleTime: 60 * 60 * 12,
    queryFn: () => {
      return api.CreditPortal.getE6Program({});
    },
    onSuccess: (dbRes: IE6ProgramList[]) => {
      if (!dbRes) return;

      dispatch(setE6ProgramList(dbRes));
    },
  });
}

export function useGetE6Program() {
  const { rawE6ProgramList, e6ProgramListEnum } =
    useAppSelector(selectE6ProgramList);

  return { rawE6ProgramList, e6ProgramListEnum };
}
