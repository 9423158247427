import { ChangeEvent, useState } from "react";

import { Container } from "@mui/system";
import {
  EnumDirection,
  EnumTransactionType,
  EnumWalletType,
} from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Master";
import { EnumE6TransactionType } from "@wallet-manager/pfh-node-def-types/dist/src/DbModel/Statement";

import {
  MpTextField,
  MpTextFieldMultiple,
  MultipleSelection,
  SingleSelection,
} from "../../../components";
import { useDatePicker } from "../../../components/DatePicker";
import { SelectChangeEvent } from "../../../components/MuiGenerals";
import CollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters";
import ToggleFilterButton from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton";
import useCollapsibleFilters from "../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters";
import FilterSectionActionRow from "../../../features/common/filterTable/layout/FilterSectionActionRow";
import { Filter } from "../../../features/common/filterTable/types";
import { useGetMerchant, useTranslation } from "../../../hooks";
import useCurrencyList from "../../../hooks/useCurrencyList";
import { strToStrArrForFilter } from "../../../utils/helper";
import {
  Ifield,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from "./config";

function ViewFilter() {
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifield>(initFields);

  const merchantObj = useGetMerchant();
  const { CurrencyListEnum } = useCurrencyList();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
  };

  const zusParams = useZusParams();

  const getParams: () => IsearchParam = () => {
    const {
      transactionTypes: transTypes,
      customerNo,
      fromCustomerNo,
      toCustomerNo,

      ...rest
    } = fields;

    const customerNumbers = strToStrArrForFilter(customerNo);
    const fromCustomers = strToStrArrForFilter(fromCustomerNo);
    const toCustomers = strToStrArrForFilter(toCustomerNo);

    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;

    return {
      ...initZusParams,
      ...rest,
      transTypes,
      customerNumbers,
      fromCustomers,
      toCustomers,
      createdDateFrom,
      createdDateTo,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.merchantName,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.merchantName) })}
          value={fields.merchantId}
          onChange={onChange("merchantId")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, merchantId: "" }))
          }
          nameFn={(name) => name}
          isNoSorting
          enumData={merchantObj}
        />
      ),
    },
    {
      labelKey: TK.orderId,
      filter: (
        <MpTextField
          label={tc("phInputField", { fieldName: t(TK.orderId) })}
          value={fields.orderId}
          onChange={onChange("orderId")}
        />
      ),
    },
    {
      labelKey: TK.customerNo,
      filter: (
        <MpTextFieldMultiple
          label={tc("phInputField", { fieldName: t(TK.customerNo) })}
          value={fields.customerNo}
          onChange={onChange("customerNo")}
          count={apiParams.customerNumbers.length}
        />
      ),
    },
    {
      labelKey: TK.direction,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.direction) })}
          value={fields.direction}
          onChange={onChange("direction")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, direction: "" }))
          }
          enumData={EnumDirection}
        />
      ),
    },
    {
      labelKey: TK.walletType,
      filter: (
        <SingleSelection
          label={tc("phSelection", { fieldName: t(TK.walletType) })}
          value={fields.walletType}
          onChange={onChange("walletType")}
          clearSelect={() =>
            setFields((fields) => ({ ...fields, walletType: "" }))
          }
          enumData={EnumWalletType}
        />
      ),
    },
    {
      labelKey: TK.transactionType,
      filter: (
        <MultipleSelection
          label={tc("phSelection", { fieldName: t(TK.transactionType) })}
          value={fields.transactionTypes}
          onChange={(transactionTypes) =>
            setFields((fields) => ({ ...fields, transactionTypes }))
          }
          enumData={EnumTransactionType}
        />
      ),
    },
    {
      labelKey: TK.currency,
      filter: (
        <MultipleSelection
          label={tc("phSelection", { fieldName: t(TK.currency) })}
          value={fields.currencies}
          onChange={(currencies) =>
            setFields((fields) => ({ ...fields, currencies }))
          }
          nameFn={(name) => name}
          enumData={CurrencyListEnum}
        />
      ),
    },
    {
      labelKey: TK.from,
      filter: (
        <MpTextFieldMultiple
          label={tc("phInputField", { fieldName: t(TK.from) })}
          value={fields.fromCustomerNo}
          onChange={onChange("fromCustomerNo")}
          count={apiParams.fromCustomers.length}
        />
      ),
    },
    {
      labelKey: TK.to,
      filter: (
        <MpTextFieldMultiple
          label={tc("phInputField", { fieldName: t(TK.to) })}
          value={fields.toCustomerNo}
          onChange={onChange("toCustomerNo")}
          count={apiParams.toCustomers.length}
        />
      ),
    },
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <FilterSectionActionRow>
        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>

      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}

export default ViewFilter;
