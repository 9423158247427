import { EPtCard } from "@wallet-manager/pfh-node-def-types/dist/src/Endpoint/CreditPortal";

import axios from "../axiosInstance";

const endPoint = {
  ...EPtCard.EPtCard,
};

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.getAll, input);
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.export, input);
}

async function postReissueCard(params: any, config?: any) {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post(endPoint.reissueCard, input);
}

async function postTerminateCard(params: any, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.terminateCard, input);
}

async function getDetails(params: any, config?: any) {
  const input = {
    params: {
      id: params.id,
    },
    ...config,
  };
  return await axios.get(endPoint.details, input);
}

export interface IGetTokenListResponse {
  externalReference: string;
  tokenSuffix: string;
  tokenRequestorId: string;
  walletId: string;
  currentStatus: string;
  provisionId: string;
  tokenType: string;
  creationTime: string;
}

async function getTokenList(
  params: { merchantId: number; cardNumber: string },
  config?: any
) {
  const input = {
    params: {
      ...params,
    },
    ...config,
  };
  return await axios.get<never, IGetTokenListResponse[]>(
    endPoint.listToken,
    input
  );
}

async function activate(
  params: {
    merchantId: number;
    cardNumber: string;
    externalReference: string;
  },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(endPoint.activateToken, input);
}

export default {
  getAll,
  getExport,
  postReissueCard,
  postTerminateCard,
  getDetails,
  getTokenList,
  activate,
};
