import { useTranslation } from "../../../../../hooks";
import { translatePrefix } from "../../config"
import { Box } from '../../../../../components/MuiGenerals';
import FlexCenterBox from "../../../../../components/Layout/FlexCenterBox";
import Row from "../../../../CreditManagement/CardList/components/ReissueCardDialog/components/Row";
import StatusRadio from "./StatusRadio";
import { updateCardProfileStatusFields } from "./types";

interface IProps {
    fields: updateCardProfileStatusFields,
    setField: React.Dispatch<React.SetStateAction<updateCardProfileStatusFields>>
}

const Content = (props: IProps) => {
    const { fields, setField } = props;
    const { t, tc } = useTranslation(translatePrefix);

    const updateField = (fieldName: keyof updateCardProfileStatusFields, value: any) => {
        setField((fields) => {
          return {
            ...fields,
            [fieldName]: value,
          };
        });
      };

    return (
        <Box>
           <FlexCenterBox isColumn style={{ marginTop: "24px" }}>
                <Row title={t("merchant_name")} content={fields.merchantName} />
                <Row title={t("program_name")} content={fields.programName} />
                <Row title={t("card_profile_name")} content={fields.cardProfileName} />
                <Row title={t("status")} content={
                    <StatusRadio 
                        value={String(fields.status)}
                        onChange={(e) => {updateField('status', parseInt(e.target.value))}}
                    />
                } />
            </FlexCenterBox>     
        </Box>
    );
}

export default Content;