import HorizontalStackBarChart from "../../../../../components/Chart/HorizontalStackBarChart";
import LineChart from "../../../../../components/Chart/LineChart";
import DoughnutChart from "../../../../../components/Chart/DoughnutChart";
import { IChartBoardChart } from "..";
import { EnumChartType } from "../types/EnumChartType";

export const mapChartTypeToChartComponent = (chart: IChartBoardChart) => {
  const {
    type,
    data,
    title,
    width,
    legendAlign,
    dataKey,
    nameKey,
    height,
    dataType,
    chartBoardCategory
  } = chart;

  switch (type) {
    case EnumChartType.HorizontalStackBarChart:
      return (
        <HorizontalStackBarChart
          data={data}
          barValueKey={
            dataKey ? (typeof dataKey === "string" ? [dataKey] : dataKey) : []
          }
          nameKey={nameKey}
          valueKey={dataKey && typeof dataKey === "string" ? dataKey : ""}
          legendAlign={legendAlign}
          width={width}
          height={height - 70}
          dataType={dataType}
          chartBoardCategory={chartBoardCategory}
        />
      );

    case EnumChartType.LineChart:
      return (
        <LineChart
          data={data}
          height={height - 100}
          lines={chart?.line}
          yAxisDisplay={true}
          dataType={dataType}
          chartBoardCategory={chartBoardCategory}
        />
      );
    case EnumChartType.DoughnutChart:
      return (
        <DoughnutChart
          data={data}
          dataKey={
            dataKey ? (typeof dataKey === "string" ? dataKey : dataKey[0]) : ""
          }
          width={width}
          height={height - 70}
        />
      );
    default:
      return <></>;
  }
};
