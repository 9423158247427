import { FilterTable } from "../../../../../../../components/Layout";
import { ICardData } from "../../../../types/ICard";
import ViewTable from "./ViewTable";

interface IProps {
  rowData: ICardData;
}

const Content = ({ rowData }: IProps) => {
  return <ViewTable rowData={rowData} />;
};

export default Content;
