import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import { FeatureCode } from "@wallet-manager/pfh-node-def-types";

import api from "../../../../../../../api";
import { IGetTokenListResponse } from "../../../../../../../api/CreditManagement/cardList";
import { ButtonMenu, LoadingDialog } from "../../../../../../../components";
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab,
} from "../../../../../../../components/Layout";
import { Container } from "../../../../../../../components/MuiGenerals";
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from "../../../../../../../hooks";
import { useGenGridCol } from "../../../../../../../utils/ComponentHelper";
import { dataGridDefaults } from "../../../../../../../utils/constant";
import { toDisplayTime } from "../../../../../../../utils/helper";
import { customSx } from "../../../../../../../utils/styling";
import { useZusDialogStore } from "../../../../../../../zustand/store";
import { useZusParams } from "../../../../config";
import { ICardData } from "../../../../types/ICard";

interface IProps {
  rowData: ICardData;
}

function ViewTable({ rowData }: IProps) {
  const { alerting } = useAlerting();
  const { tc } = useTranslation("cardList");
  const { hasPermission } = usePermission();

  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <TableTab>
        <TableList rowData={rowData} />
      </TableTab>
    </Container>
  );
}

function TableList({ rowData }: { rowData: ICardData }) {
  const [isShowLoading, setIsShowLoading] = useState(false);

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();

  const { t, tc } = useTranslation("cardList");
  const listMapping = useListMapping("table");

  const isHaveActivatePermission = hasPermission(
    FeatureCode.FeatureCreditManagement.CardList.TokenList.prefix
  );

  const listRes = useQuery({
    queryKey: ["cardList-tokenList", zusParams.body, zusParams.refetchCounter],
    queryFn: async () => {
      return api.CreditManagement.cardList.getTokenList({
        merchantId: Number(rowData.rawData.merchantId),
        cardNumber: rowData.rawData.cardNumber,
      });
    },
  });

  const count = listRes?.data?.length || 0;

  const omitKeys: string[] = [];
  const content: GridRowsProp = listMapping(listRes?.data || [], omitKeys);

  const OperationCell = (params: GridRenderCellParams<any, any, any>) => {
    const generateOperationMenuOptions = (
      params: GridRenderCellParams<any, any, any>
    ) => {
      const getIsActivateButtonDisabled = (status: any) => {
        return status === "Active";
      };

      const status = params.row.currentStatus;
      const isActivateButtonDisabled = getIsActivateButtonDisabled(status);

      const handleClick = async () => {
        setIsShowLoading(true);
        const res = await api.CreditManagement.cardList.activate({
          merchantId: rowData?.rawData?.merchantId,
          cardNumber: rowData?.rawData?.cardNumber,
          externalReference: params.row?.externalReference,
        });
        setIsShowLoading(false);

        if (!res) {
          return;
        }
        listRes.refetch();
        alerting("success", t("activateSuccess"));
      };

      return [
        {
          name: t("activate"),
          onClickFn: handleClick,
          noShow: !isHaveActivatePermission,
          isDisabled: isActivateButtonDisabled,
        },
      ];
    };

    return (
      <>
        <ButtonMenu
          title={t("operation")}
          options={generateOperationMenuOptions(params)}
        />
      </>
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol("operation", {
      renderCell: OperationCell,
      hide: !isHaveActivatePermission,
    }),

    useGenGridCol("tokenRequestId"),
    useGenGridCol("tokenType"),
    useGenGridCol("suffix"),
    useGenGridCol("currentStatus"),
    useGenGridCol("walletId"),
    useGenGridCol("externalReference"),
    useGenGridCol("creationTime"),
  ];

  const TABLE_ROWS_PER_PAGE = 20;

  function paginateData(data: any, itemsPerPage: number, page: number) {
    if (!data || Object.keys(data).length === 0) return [];

    const pages = Math.ceil(data.length / itemsPerPage);
    const paginatedData = [];

    for (let i = 0; i < pages; i++) {
      const start = i * itemsPerPage;
      const end = start + itemsPerPage;
      paginatedData[i] = data.slice(start, end);
    }

    return paginatedData[page];
  }

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;

  return (
    <>
      <LoadingDialog forceOpen={isShowLoading} />
      <DataGrid
        {...dataGridDefaults}
        key={"cardList-tokenList"}
        rows={paginateData(content, TABLE_ROWS_PER_PAGE, zusParams.body.page)}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
        pageSize={TABLE_ROWS_PER_PAGE}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const listMappingTransform = useListMappingTransform(mode);

  const { tb, translate } = useTranslation("cardList");

  const listMapping = (
    array: IGetTokenListResponse[],
    omitKeys: string[] = []
  ) => {
    const res = array.map((item: IGetTokenListResponse) => {
      const mappedResult = [
        ["rawData", item],
        ["tokenRequestId", item?.tokenRequestorId],
        ["tokenType", item?.tokenType],
        ["suffix", item?.tokenSuffix],
        ["currentStatus", item?.currentStatus],
        ["walletId", item?.walletId],
        ["externalReference", item?.externalReference],
        ["creationTime", toDisplayTime(item?.creationTime)],
      ].filter(([key]) => !omitKeys.includes(key as string));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
