import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "./store";

export interface IE6ProgramList {
  creationTime: number;
  modifiedTime: number;
  id: string;
  name: string;
  defaultHomeCurrency: string;
  currencies: string[];
  language: string;
  bankName: string;
  partnerName: string;
  timeZone: string;
}

interface E6ProgramListState {
  rawE6ProgramList: IE6ProgramList[];
  e6ProgramListEnum: Record<string, string>;
}

const initialState: E6ProgramListState = {
  rawE6ProgramList: [],
  e6ProgramListEnum: {},
};

export const e6ProgramListSlice = createSlice({
  name: "e6ProgramList",
  initialState,
  reducers: {
    setE6ProgramList: (state, action: PayloadAction<IE6ProgramList[]>) => {
      state.rawE6ProgramList = action.payload;

      state.e6ProgramListEnum = action.payload.reduce(
        (acc: Record<string, string>, curr: IE6ProgramList) => {
          return {
            ...acc,
            [curr.name]: curr.name,
          };
        },
        {}
      );
    },
  },
});

export const { setE6ProgramList } = e6ProgramListSlice.actions;

export const selectE6ProgramList = (state: RootState) => state.e6ProgramList;
export default e6ProgramListSlice.reducer;
